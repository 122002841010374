import { Form } from 'antd';
import React from 'react';
import { connect } from "react-redux";

const FormItem = Form.Item;

class SignUpOk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmDirty: false,
            autoCompleteResult: [],
            modalIsOpen: false,
            address: null
        };
    }

    // onClickDownload = () => {
    //     if (/Android/i.test(navigator.userAgent)) {
    //         global.location.href = 'http://play.google.com/store/apps/details?id=com.sbwallet';
    //     } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    //         //   global.location.href = 'https://itunes.apple.com/kr/app/%EB%B8%94%EB%9E%8D%EC%8A%A4/id1428926701?mt=8'
    //         alert("아이폰은 현재 출시 대기중 입니다.");
    //     } else {
    //         alert("모바일에서 설치해주세요.");
    //     }
    // }
    render() {
        const { language, string } = this.props;
        return (
            <div className="wrapper">
                <div className="header">
                    {string.welcome}
                </div>
                <div align="center" className="signupok">
                    <div className="signupok-text">
                    {string.signUpMsg01}<br />
                    {string.signUpMsg02}
                    </div>
                    {/* <div className="signupok-button" onClick={this.onClickDownload}>
                        앱 설치
                    </div> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      language: state.stringReducer.language,
      string: state.stringReducer.string
    };
  };
export default connect(mapStateToProps)(SignUpOk);