import { Form, Input, Checkbox, Button, Modal, Table, Divider } from 'antd';
import React from 'react';
import "../css/signUp.scss";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { stringReducer, setLogin, setLogout } from "../actions";
import { Link, Route, withRouter } from "react-router-dom";
import { formatBalance } from "../util/coinUtil";
import string from '../language/StringKo';
import moment from "moment";
import Header from '../components/Header';
import Top from './Top';
import { LeftSquareOutlined, } from '@ant-design/icons';

const FormItem = Form.Item;

class Notice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: {
                total: 0,
                current: 1,
                pageSize: 5,
            },
            list: [],
        }
    }

    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        }, () => this.getNoticeList());
    };

    getNoticeList = () => {

        let pageNum = this.state.pagination.current;
        let pageSize = this.state.pagination.pageSize;
        httpGet(httpUrl.noticeList, [pageNum, pageSize], {})
            .then(res => {
                const pagination = { ...this.state.pagination }
                pagination.total = res.data.totalCount
                this.setState({
                    list: res.data.noticeList,
                    pagination,
                })
            })
    }
    componentDidMount() {
        // this.requestSystemListBlockchain();
        // this.requestBalance();
        this.getNoticeList();
    }
    requestBalance = () => {
        console.log("##### Request balance")
        httpGet(httpUrl.balance, [0], {}).then((result) => {
            result = result.data;
            console.log(result)
            this.setState({ balances: result })
        }).catch(e => {
            console.log("## requestBalance error: " + e)
        });
    }
    requestSystemListBlockchain = () => {
        console.log("## Loading systemListBlockchain");
        httpGet(httpUrl.systemListBlockchain, [this.props.language], {}).then((result) => {
            result = result.data;
            console.log('## systemListBlockchain loaded');
            this.setState({ blockchains: result })
        }).catch((e) => {
            console.log('## systemListBlockchain load error: ' + e)
        });
    }
    changeShowContent = (idx) => {
        if (this.state.showContent === idx) {
            this.setState({
                showContent: 0,
            });
        } else
            this.setState({
                showContent: idx,
            });
    };
    logout = () => {
        const { language, string, setLogout, history } = this.props;
        Modal.confirm({
            title: string.myinfoLogout,
            content: string.myinfoLogoutConfirm,
            okText: string.ok,
            cancelText: string.cancel,
            onOk() {
                setLogout();
                history.push('/');
            },
            onCancel() {
            },
        });
    }

    back = () => {
        this.props.history.goBack();
    }
    render() {
        const columns = [
            {
                title: "",
                dataIndex: "title",
                className: "column-center",
                render: (data, row) => (
                    <>
                        <div
                            className="noticeTag"
                            onClick={() => {
                                this.changeShowContent(row.idx);
                            }}
                        >
                            <div className="notice-title">{data}</div>
                            {row.category === "NEWS" ?
                                <div className="noticeTag1">{string.news}</div> : row.category === "NOTE" ?
                                    <div className="noticeTag2">{string.note1}</div> : ""}
                        </div>
                        {this.state.showContent === row.idx && (
                            <div className="noticeContent">
                                {row.content.split("\n").map((line, index) => {
                                    return (
                                        <span key={index}>
                                            {line}
                                            <br />
                                        </span>
                                    );
                                })}
                            </div>
                        )}
                    </>
                ),
            },

            {
                title: "",
                dataIndex: "createDate",
                className: "column-center noticeDate",
                width: "20%",
                render: (data) => <div style={{ color: 'gray', fontWeight: 500 }}>{moment(data).format("YYYY-MM-DD")}</div>
            },
        ];
        console.log(this.props.userinfo)
        const { language, string } = this.props;
        return (
            <div className="wrapper">
                <Top />


                <div className="container" >
                    <Header />
                    <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>


                        <div className="main-box">
                            <div className="main-title">
                                {/* <div className="main-text">{string.notice}</div> */}
                                <div className="back-btn" onClick={this.back}>
                                    <LeftSquareOutlined style={{ fontSize: 30 }} />
                                </div>
                            </div>
                        </div>

                        <Divider />
                        <div className="currencyNotice">
                            <Table
                                className="noticeTable"
                                rowKey={(record) => record.idx}
                                columns={columns}
                                dataSource={this.state.list}
                                pagination={this.state.pagination}
                                onChange={this.handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string,
        isLogin: state.loginReducer.isLogin,
        userinfo: state.loginReducer.userinfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: lan => {
            dispatch(stringReducer(lan));
        },
        setLogin: userinfo => {
            dispatch(setLogin(userinfo));
        },
        setLogout: lan => {
            dispatch(setLogout());
        },
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notice));