import React, { Component } from 'react';
import Main from './components/Main';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import con from './const';
import { httpGet, httpUrl } from './api/httpClient';

class App extends Component {
  constructor(props) {
    super(props);
    this.state={
      exchangeRateLoaded: false,
    }
  }

  componentDidMount() {
    this.requestCurrencyExchangeRate()
  }

  requestCurrencyExchangeRate = (hide) => {
    if(con.enableForeignExchangeRate){
      console.log("##### Request currencyExchangeRate")
      return new Promise((resolve, reject) => {
        httpGet(httpUrl.currencyExchangeRate, [], {}, hide).then((result)=>{
          console.log("## Request currencyExchangeRate loaded")
          result = result.data;
          global.exchangeRate = [
            1,
            result.find(x => x.currency == 'KRWUSD').rate,
            result.find(x => x.currency == 'KRWCNY').rate,
            result.find(x => x.currency == 'KRWJPY').rate,
          ]
          this.state.exchangeRateLoaded = true;
          resolve();
        }).catch(e=>{
          console.log("## requestCurrencyExchangeRate error: " + e)
          reject();
        });
      })
    } else {
      return new Promise((resolve, reject) => {
        this.state.exchangeRateLoaded = true;
        global.exchangeRate = [1, 1, 1, 1];
        resolve();
      })
    }
  }
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/:page" component={Main} />
          <Route path="/" component={Main} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
