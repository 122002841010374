import { Form, Input, Checkbox, Button, Modal, Table, Divider } from 'antd';
import React from 'react';
import "../../css/signUp.scss";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { stringReducer, setLogin, setLogout } from "../../actions";
import { Link, Route, withRouter } from "react-router-dom";
import { formatBalance } from "../../util/coinUtil";
import string from '../../language/StringKo';
import moment from "moment";
import { comma } from '../../util/numberUtil';
import Header from '../../components/Header';
import AddressValidation from '../../util/addressValidation';
import Coin from '../../lib/coin/coin';
import con from '../../const'
import Top from '../../pages/Top';
import { LeftSquareOutlined, } from '@ant-design/icons';

const FormItem = Form.Item;

class WalletSend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sendAmount: '',
            targetAddress: '',

            checkConfirm: false,
            destinationTag: '',

            secPassword: '',
            otp: '',

            memo: '',

            selectedCoinType: this.props.location.state.data.coinType,

            sendConfirmVisible: false,
            sendInfo: {
            },
            sendTarget: {
            }
        }
    }

    componentDidMount() {
        this.initData();
    }
    initData = () => {
        this.setState({
            sendAmount: '',
            targetAddress: '',

            checkConfirm: false,
            destinationTag: '',

            secPassword: '',
            otp: '',

            memo: '',

            selectedCoinType: this.props.location.state.data.coinType,

            sendConfirmVisible: false,
            sendInfo: {
            },
            sendTarget: {
            }
        })
    };

    send = () => {
        // if(!this.state.address){
        //     Modal.info({
        //         content: string.pleaseInsertAddress
        //     })
        //     return;
        // }
        // if(!this.state.amount){
        //     Modal.info({
        //         content: string.pleaseInsertAmount
        //     })
        //     return;
        // }
        // if(!this.state.securityPw){
        //     Modal.info({
        //         content: string.pleaseInsertSecurityPw
        //     })
        //     return;
        // }
        // if(!this.state.otp){
        //     Modal.info({
        //         content: string.pleaseInsertOtp
        //     })
        //     return;
        // }
    }

    onClickSendCoin = async (values) => {
        console.log('## send coin selected cointype')
        let coin = this.props.coinList.find(x => x.coinType == this.state.selectedCoinType);

        if (this.state.targetAddress.length <= 0) {
            Modal.info({ content: string.sendCoinInputAddress })
            return;
        }

        if (this.state.sendAmount == '' || this.state.sendAmount <= 0) {
            Modal.info({ content: string.sendCoinInputAmount })
            return;
        }

        if (Math.floor(this.state.sendAmount * Coin.decimalPlace) / Coin.decimalPlace <= 0) {
            Modal.info({ content: string.sendCoinInputAmountLow })
            return;
        }

        if (this.state.secPassword.length < 4) {
            Modal.info({ content: string.sendCoinFailedNoSecPassword })
            return;
        }

        if (this.props.userinfo.securityLevel > 1 && this.state.otp.length < 6) {
            Modal.info({ content: string.inputOtpValidation })
            return;
        }

        if (!this.state.checkConfirm) {
            Modal.info({ content: string.sendCoinNeedConfirm })
            return;
        }
        // 수수료 계산
        let result = await httpGet(httpUrl.walletCheckFee, [this.state.selectedCoinType, values.targetAddress])
        console.log(JSON.stringify(result));
        // result = result.data;

        let fee;

        if (result.result != 'SUCCESS') {
            Modal.info({ content: string.feeRequestFailed })
            return;
        }
        fee = result.data / Coin.getCoinUnit(this.state.selectedCoinType).base;

        console.log("## fee: " + fee);

        if (coin.balance < fee) {
            Modal.info({
                content: string.sendCoinLowFee + " (" + fee + " " + coin.symbol + ")"
            })
            return;
        }

        // 코인 전송 시작

        console.log("##### Sending coin");

        let sendAmount = parseFloat(this.state.sendAmount);
        let sendAmountBase = Math.round(sendAmount * Coin.decimalPlace) / Coin.decimalPlace * Coin.getCoinUnit(coin.coinType).base;
        let secPasswordHash = crypto.createHash('sha256').update(this.state.secPassword + this.props.userinfo.id, 'utf8').digest().toString('hex');

        let target = {
            amount: sendAmountBase,
            coinType: coin.coinType,
            to: this.state.targetAddress,
            destinationTag: 0,
            otpCode: this.state.otp,
            securityPassword: secPasswordHash,
            memo: this.state.memo,
            fee: fee,
        };

        console.log('## send target: ' + JSON.stringify(target));

        this.confirmSendCoin(coin, target);
    }

    confirmSendCoin = (coin, target) => {
        console.log('##### confirmSendCoin');
        console.log(coin);
        console.log(target);

        this.setState({
            sendConfirmVisible: true,
            sendInfo: {
                coinName: coin.name,
                coinSymbol: coin.symbol,
                address: target.to,
                amount: Math.round(target.amount / Coin.getCoinUnit(this.state.selectedCoinType).base * Coin.decimalPlace) / Coin.decimalPlace,
                fee: target.fee,
                feeUnit: coin.symbol,
                destinationTag: target.destinationTag,
            },
            sendTarget: target
        })
    }

    sendCoin = async () => {
        let result = await httpPost(httpUrl.walletSend, [], this.state.sendTarget);

        console.log(JSON.stringify(result));
        result = result.data;

        if (result == 'SUCCESS' || result == 'APPROVAL') {
            console.log("## sent success");
            Modal.info({
                title: string.sendCoinSuccessTitle,
                content: (
                    <div>
                        {string.sendCoinSuccess}
                    </div>
                ),
                onOk() { },
            });
            this.initData();
            return;
        } else {
            let message = string.sendCoinFailed;
            if (result == 'INVALID_ADDRESS') message = string.sendCoinFailedInvalidAddress;
            else if (result == 'INSUFFICIENT_BALANCE') message = string.sendCoinFailedInsufficientBalance;
            else if (result == 'LOCKED') message = string.sendCoinFailedLocked + "\n" + string.sendCoinFailedDetail;
            else if (result == 'INVALID_OTP') message = string.sendCoinFailedInvalidOtp;
            else if (result == 'INVALID_SECURITY_PASSWORD') message = string.sendCoinFailedInvalidSecPassword;
            else if (result == 'REQUIRED_OTP') message = string.sendCoinFailedOtpRequired;
            else if (result == 'LIMIT_EXCEED') message = string.sendCoinFailedLimitExceed;
            else if (result == 'INVALID_DESTINATIONTAG') message = string.sendCoinFailedInvalidDesTag;
            else if (result == 'REQUIRED_OTP_DAILY') message = string.sendCoinFailedOtpRequired;
            else if (result == 'CANNOT_SEND_ORGANIZATION') message = string.sendCoinFailedOrganization;
            else if (result == 'INVALID_SECURITY_LEVEL') message = string.sendCoinFailedSecurityLevelRequired;

            Modal.info({
                title: string.sendCoinFailedTitle,
                content: (
                    <div>
                        {message}
                    </div>
                ),
                onOk() { },
            });
        }
    };

    back = () => {
        this.props.history.goBack();
    }

    render() {
        const { language, string } = this.props;

        return (
            <div className="wrapper">
                <Top />
                <div className="container">
                    <Header />
                    <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                        <div className="main-title" style={{ texAlign: "left" }}>
                            <div className="back-btn" onClick={this.back}>
                                <LeftSquareOutlined style={{ fontSize: 30 }} />
                            </div>
                        </div>
                        {/* <div className="main-box">
                            <div className="main-title">
                                <div className="main-text">{string.walletSend}</div>

                            </div>
                        </div> */}
                        <div className="charge-inner">
                            <div className="charge-inner2">
                                <div>
                                    {/* {this.props.location.state.data.symbol} */}현재 잔액
                                </div>
                                <div>
                                    <span style={{ color: "#5663ff", marginRight: 5 }}>{this.props.location.state.data.balance}</span>
                                    {this.props.location.state.data.symbol}
                                </div>
                            </div>

                            <div style={{ margin: 20, textAlign: "left", fontSize: 18, fontWeight: "bold" }}>
                                {string.depositAddress}
                            </div>
                            <Input
                                style={{ width: "94%", borderRadius: 20, padding: 15, marginBottom: 10 }}
                                placeholder={string.insertAddress}
                                onChange={(e) => this.setState({
                                    targetAddress: e.target.value
                                })}
                                value={this.state.targetAddress}
                            />
                            <Input
                                style={{ width: "94%", borderRadius: 20, padding: 15, marginBottom: 10 }}
                                placeholder={string.insertAmount}
                                onChange={(e) => this.setState({
                                    sendAmount: e.target.value
                                })}
                                value={this.state.sendAmount}
                            />
                            <Input
                                style={{ width: "94%", borderRadius: 20, padding: 15, marginBottom: 30 }}
                                placeholder={string.insertMemo}
                                onChange={(e) => this.setState({
                                    memo: e.target.value
                                })}
                                value={this.state.memo}
                            />
                            <Input.Password
                                style={{ width: "94%", borderRadius: 20, padding: 15, marginBottom: 10 }}
                                placeholder={string.securityPw}
                                onChange={(e) => this.setState({
                                    secPassword: e.target.value
                                })}
                                value={this.state.secPassword}
                            />
                            {this.props.userinfo.securityLevel >= 2 && (
                                <Input
                                    style={{ width: "94%", borderRadius: 20, padding: 15, marginBottom: 20 }}
                                    placeholder='OTP'
                                    onChange={(e) => this.setState({
                                        otp: e.target.value
                                    })}
                                    value={this.state.otp}
                                />
                            )}

                            <div className="contentBlock">

                                <div
                                    className="content-box"
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        margin: "0 20px"
                                    }}
                                >
                                    <div>{string.sendCoinCautionConfirm}</div>
                                    <Checkbox
                                        leftTextStyle={{
                                            fontSize: 15.7,
                                            letterSpacing: 0.05,
                                            color: '#000',
                                            lineHeight: 20,
                                        }}
                                        onClick={() => {
                                            this.setState({ checkConfirm: !this.state.checkConfirm });
                                        }}
                                        isChecked={this.state.checkConfirm}

                                        checkBoxColor="#e1e1e1"
                                    />
                                </div>
                                <div
                                    className="content-box"
                                    style={{ fontWeight: "normal", fontSize: 16, textAlign: "left", margin: "0 20px" }}
                                >
                                    {string.sendCoinCautionConfirmDescriptionEx.join(" ")}
                                </div>

                                <div className="send-btn">
                                    <Button
                                        onClick={this.onClickSendCoin}
                                    >
                                        {string.walletSend}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {this.state.sendConfirmVisible && (
                    <Modal className="trade-send-confirm-modal" visible={this.state.sendConfirmVisible} onOk={this.sendCoin} onCancel={() => this.setState({ sendConfirmVisible: false })} cancelText={string.close} okText={string.ok}>
                        <div className="trade-send-confirm-title">{string.sendCoinConfirmDlgTtl}</div>
                        <div>{string.sendCoinConfirmMessage}</div>
                        <div className="trade-send-confirm-item">{string.address}:</div>
                        <div className="trade-send-confirm-item" style={{ marginLeft: '10px', color: '#693cff' }}>{this.state.sendInfo.address}</div><br />
                        <div className="trade-send-confirm-item">{string.value}:</div>
                        <div className="trade-send-confirm-item" style={{ marginLeft: '10px' }}>{this.state.sendInfo.amount}</div>
                        <div className="trade-send-confirm-item">{this.state.sendInfo.coinSymbol}</div><br />
                        <div className="trade-send-confirm-item">{string.fee}:</div>
                        {this.state.sendInfo.fee == 0 ? (
                            <div className="trade-send-confirm-item" style={{ marginLeft: '10px' }}>{string.exemption}</div>
                        ) : (
                            <div className="trade-send-confirm-item" style={{ marginLeft: '10px' }}>
                                <div className="trade-send-confirm-item">{this.state.sendInfo.fee}</div>
                                <div className="trade-send-confirm-item">{this.state.sendInfo.feeUnit}</div>
                            </div>
                        )}
                    </Modal>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string,
        isLogin: state.loginReducer.isLogin,
        userinfo: state.loginReducer.userinfo,
        coinList: state.coinListReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: lan => {
            dispatch(stringReducer(lan));
        },
        setLogin: userinfo => {
            dispatch(setLogin(userinfo));
        },
        setLogout: lan => {
            dispatch(setLogout());
        },
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WalletSend));