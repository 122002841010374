import { Form } from 'antd';
import React from 'react';
import { connect } from "react-redux";

class Privacy extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { language, string } = this.props;
        return (
            <div>
                <div className="agreement-content-title">
                    {string.policyTitle}
                </div>
                    
                <div>
                    {string.policyContent.map((value, index) => {
                        let result = [];
                        if (value.title)
                            result.push(
                            <div key={'title_' + index} className="agreement-content-title">
                                {value.title}
                            </div>
                            );
                        if (value.content)
                            result.push(
                            <div key={'content_' + index} className="agreement-content-body">
                                {value.content}
                            </div>
                            );
                        return result;
                    })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      language: state.stringReducer.language,
      string: state.stringReducer.string
    };
  };
  export default connect(mapStateToProps)(Privacy);