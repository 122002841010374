import { Form, Switch, Button, Divider, Input, Modal,Image } from 'antd';
import React from 'react';
import "../css/signUp.scss";
import { httpGet, httpUrl, httpPost, httpPut, makeUrl, imageUrl } from "../api/httpClient";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Header from '../components/Header';
import { ExclamationCircleOutlined, MailOutlined,CopyOutlined } from '@ant-design/icons';
import crypto from 'crypto';
import QRCode from "react-qr-code";
import axios from "axios";
import imageCompression from 'browser-image-compression';
import { setLogin} from "../actions";

import Top from './Top';
import { stat } from 'fs';
const FormItem = Form.Item;

class Mypage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            onOtp: this.props.userinfo.securityLevel >= 2 ? true : false,
            onOtpVisible:false,
            showSecuritySettings: true,
            changeNameVisible:false,
            changePwVisible:false,
            changeSecPwVisible:false,
            modifyOtpVisible:false,
            certiVerified: false,
            kycVisible: false,
            timer: 180,
            onTimer: false,
            newName:'',
            password:'',
            newPassword:'',
            newSecPassword:'',
            email: this.props.userinfo.id != undefined ? this.props.userinfo.id : '',
            otpKeyUrl: '',
            otpKey: '',
            authCode: '',
            msg: '',
            verifyCode:'',
            certiCode:'',
            kycStatus: -1,
            photoIdx:0,
        }
    }

    componentDidMount() {
       this.reloadKyc();
    }

    componentWillUnmount() {
       
    }

    onClickOtp = () => {
        if (this.props.userinfo.securityLevel >= 2) {
            Modal.error({ content: this.props.string.alreadyOtp });   
          return;
        } else {
            this.setState({onOtp : true, onOtpVisible: !this.state.onOtpVisible})
            this.getOtpKey();
        }
    }

    onChangePassword = () => {
        let password = this.state.password + this.props.userinfo.email;
        let passwordHash = crypto.createHash('sha256').update(password, 'utf8').digest().toString('hex');
        let newPassword = this.state.newPassword + this.props.userinfo.email;
        let newPasswordHash = crypto.createHash('sha256').update(newPassword, 'utf8').digest().toString('hex');

        httpPost(httpUrl.changePassword2, [], { password: passwordHash, newPassword: newPasswordHash }).then((result)=>{
        result = result.data;     
        if (result.result){
            this.setState({changePwVisible : false, password: '', newPassword:''});            
            Modal.success({
                content: this.props.string.setSucceed,
              });  
        } else {            
            if (result.reason == 'SAME_PASSWORD') {
                Modal.error({ content: this.props.string.changePasswordFailedSamePassword });                 
            } else if (result.reason == 'INVALID_PASSWORD') {
                Modal.error({ content: this.props.string.changePasswordFailedInvalidPassword });                
            } else { Modal.error({ content: this.props.string.changePasswordFailed });}
        }
        }).catch(e=>{
        console.log ("## changePassword error: " + e);
        });
    }

    onChangeSecPassword = () => {
        let password = this.state.password + this.props.userinfo.email;
        let passwordHash = crypto.createHash('sha256').update(password, 'utf8').digest().toString('hex');
        let newSecPassword = this.state.newSecPassword + this.props.userinfo.email;
        let newSecPasswordHash = crypto.createHash('sha256').update(newSecPassword, 'utf8').digest().toString('hex');
    
        httpPost(httpUrl.changeSecPassword, [], { password: passwordHash, newPassword: newSecPasswordHash }).then((result)=>{
          result = result.data;       
          if (result.result){
            this.setState({changeSecPwVisible : false, password: '', newSecPassword: ''});
            Modal.success({
                content: this.props.string.setSucceed,
              }); 
          } else {
            if (result.reason == 'SAME_PASSWORD') {
                Modal.error({ content: this.props.string.changePasswordFailedSamePassword });            
            } else if (result.reason == 'INVALID_PASSWORD') {
                Modal.error({ content: this.props.string.changePasswordFailedInvalidPassword });                
            } else { Modal.error({ content: this.props.string.changePasswordFailed })}
          }    
        }).catch(e=>{
          console.log ("## changeSecPassword error: " + e);
        });
    }
    // otp키 가져오기
    getOtpKey = () => {
        httpGet(httpUrl.walletOtpGetKey, [])
          .then(result => {
            result = result.data;
            console.log("## walletOtpGetKey: " + JSON.stringify(result));
            if (result.result){    
              this.setState({
                otpKeyUrl : result.url,
                otpKey: result.key,              
              });
            }
          })
          .catch((e) => {
            console.log("## walletOtpGetKey error: " + e);
          });
    }
    // otp설정
    verifyOtp = () => {
        httpGet(httpUrl.walletOtpVerify, [this.state.authCode, this.state.verifyCode])
          .then(result => {
            result = result.data;
            console.log("## walletOtpVerifyOtp: " + JSON.stringify(result));
            if (result){    
              this.setState({
                onOtp:true,
                onOtpVisible:false
              });    
              const modifyUserInfo = this.props.userinfo;
              modifyUserInfo.securityLevel = 2;
              this.props.setLogin(modifyUserInfo);
            alert(this.props.string.myinfoOtpComplete)
            } else {
           alert(this.props.string.myinfoOtpError);                 
            }
          })
          .catch((e) => {
            console.log("## walletOtpGetKey error: " + e);
          });
      }

    onCopyCode = () => {
        window.navigator.clipboard.writeText(this.state.otpKey)
        Modal.success({ content: this.props.string.otpCopied });       
    }

    // 인증번호전송
    onPressSendCertiCode = () => {
        if (this.state.certiVerified) return;
        if (this.state.onTimer) return;    
        if (this.state.phone == undefined || this.state.phone == '' || this.state.phone.length < 9) { 
            alert(this.props.string.signUpRuleMsg08);   
            return;
        } 
        let fullPhone = this.state.phone.replace(/^-/, "");
        httpPost(httpUrl.userPhoneauthGenerate, [], {phone: fullPhone}).then((result)=>{
          console.log("## userPhoneauthGenerate result: " + JSON.stringify(result));
          result = result.data.token;
          this.certiCodeToken = result;
          if(result && result != ''){
            this.setState({ onTimer: true, phoneEditable: false });    
            this.interval = setInterval(
              () => {
                if(this.state.timer == 0){
                  clearInterval(this.interval);
                  this.setState({ onTimer: false, timer: 180});
                  return;
                }
                this.setState({ timer: this.state.timer - 1 });
              },
              1000
            );
          } else {
            alert(this.props.string.certiCodeSendFail);          
            console.log("## userPhoneauthGenerate server error");
          }    
        }).catch(e=>{
          console.log ("## userPhoneauthGenerate error: " + e)
        });
      }
    //  인증번호 확인
      onPressCheckCertiCode = () => {
        if (this.state.certiVerified) return;
        if (this.state.certiCode == undefined || this.state.certiCode == '') {
            alert(this.props.string.certiCodeFail);            
            return;
        } 
        httpGet(httpUrl.userPhoneauthCheck, [this.certiCodeToken, this.state.certiCode], {}).then((result)=>{
          console.log("## userPhoneauthCheck result: " + JSON.stringify(result));
          result = result.data;    
          if(result.responseCode == "SUCCESS"){
            this.setState({ 
                certiVerified: true, modifyOtpVisible:false, phone:'', onOtp : false,  onOtpVisible:true        
            },()=> {this.changeOtp()});
            alert(this.props.string.changeOtpComplete)
          } else {
            console.log("## userPhoneauthCheck server error");
            this.setState({certiCode: ''});
            alert(this.props.string.certiCodeWrong);             
            if (result.responseCode == 'EXPIRED') {alert(this.props.string.certiCodeExpired); }
        }
        }).catch(e=>{
          console.log ("## userPhoneauthCheck error: " + e)
        });    
      }
    //   이름변경
      onChangeName = () => {       
        const regex = /^[ㄱ-ㅎ|가-힣|a-z|A-Z]+$/;
        if (!regex.test(this.state.newName)) {
            alert(this.props.string.enterNicknameValidation);    
            this.setState({newName:''})
            return;
        }
        httpPut(httpUrl.userUpdateNickname, [this.state.newName]).then((result)=>{
          result = result.data;
          if (result){  
            alert(this.props.string.setSucceed);                
          } else {
            alert(this.props.string.setFailed);            
          }    
        }).catch(e=>{
          console.log ("## userUpdateNickname error: " + e);
        });        
      }
            
    // 파일등록
    regist = () => {
        if(this.state.photoIdx === 0 || this.state.photoIdx == null) {
            alert("사진을 등록해주세요.")
            return;
        }
        httpPost(httpUrl.kycRegist, [this.state.photoIdx], {}).then((result) => {
        console.log(JSON.stringify(result));
        result = result.data;     
        if (result) {            
            console.log("## sent success");
            alert(this.props.string.fileUploadComplete)    
            this.setState({
                kycVisible:false,
            }, ()=>{this.reloadKyc()})
        } else {
            alert(this.props.string.fileUploadError)
        }        
        }).catch(e => { 
            console.log(e);
            alert(this.props.string.fileUploadError)
        });
    }

    reloadKyc = () => {    
        httpGet(httpUrl.kycStatus,[],{}).then(result => {
        //   console.log('kycStatus=' + JSON.stringify(result));
          this.setState({ kycStatus: result.data });
        })
        .catch(() => {
        });
      }
    changeOtp = () => {     
        httpPost(httpUrl.walletOtpChangeKey, [], { token: this.certiCodeToken, code: this.state.certiCode }).then((result)=>{
        result = result.data;
        console.log("## userChangeOtp result: " + JSON.stringify(result));
        if (result.result){
            this.props.setOtp(1);    
            this.setState({
                otpKeyUrl : result.url,
                otpKey: result.key,   
            })
        } else {
            alert(this.props.string.changeOtpFailed)
        }  
        }).catch(e=>{
        console.log ("## userFindPassword error: " + e);
        });
    }
    // 파일업로드
    actionImgCompress = async (file) => {
        if (!file) return; 
        const options = {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 1024,
            useWebWorker: true
        };
        try {
            const compressedFile = await imageCompression(file, options);  
            console.log(compressedFile)

            const reader = new FileReader();
            reader.readAsDataURL(compressedFile);
            reader.onloadend = () => {
                const base64data = reader.result;
                this.handleFileUpload(base64data)
            };
        } catch(e) {
            console.log(e);
        }
    }

    handleFileUpload = async (data) => {
        const byteString = atob(data.split(",")[1]);

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ia], {
          type: "image/jpeg"
        });
        const file = new File([blob], "image.jpg");

        var formData = new FormData();
        formData.append("file", file);

        await axios.post(makeUrl(httpUrl.FileUpload, []), formData, {
            withCredentials: true,
        })
        .then((res) => {
            if (
            res.status === 200 &&
            res.data.result === "SUCCESS" &&
            res.data.data.result
            ) { this.setState({photoIdx: res.data.data.idx});   
            } else {
                alert(this.props.string.fileUploadError);
            }
        })
        .catch((e) => {
            alert(this.props.string.fileUploadError);
        });     
       
    };
    render() {
        const { string } = this.props;
        console.log(this.props.userinfo.securityLevel)
        console.log(this.state.onOtp)
        return (
            <div className="wrapper">
                <Top />
                <div className="container">
                    <Header />
                    <div className="container-box" style={{ paddingBottom: '30px' }}>
                        <div className="main-box">

                    {/* 내정보 변경 */}
                            <div className="myinfo-wrap">
                                <div className="main-text">{string.myPage}</div>       
                                <div>
                                    <Button onClick={()=>{this.setState({changeNameVisible: !this.state.changeNameVisible})}} className='myinfoBtn'>
                                        {this.state.changeNameVisible ? string.close : string.change}
                                    </Button>
                                    {this.state.changeNameVisible && 
                                        <Button onClick={()=>{this.onChangeName()}} className='myinfoBtn saveBtn'>
                                            {string.saveData}
                                        </Button>                        
                                    }                                                          
                                </div>
                            </div>
                            <div className='myinfo-wrap sub'>
                                <div className='myinfo-subTitle'>{string.idEmail}</div>
                                <div className='myinfo-obj'>{this.props.userinfo.id}</div>
                            </div>
                            <div className="myinfo-wrap sub">
                                <div className='myinfo-subTitle'>{string.name}</div>
                                {this.state.changeNameVisible ? (
                                    <Input 
                                        onChange={(e)=>{this.setState({newName: e.target.value})}}
                                        defaultValue={this.props.userinfo.name} 
                                        className="myinfo-input"/>
                                ):(
                                    <div className='myinfo-obj'>{this.props.userinfo.name}</div>
                                )}
                                
                            </div>
                            <div className='myinfo-wrap sub'>
                                <div className='myinfo-subTitle'>{string.phone}</div>
                                <div className='myinfo-obj'>{this.props.userinfo.phone}</div>
                            </div>

                            <Divider />   

                    {/* 비밀번호 변경 */}
                            <div className="myinfo-wrap">
                                <div className="main-text">{string.password}</div>                         
                                <div>
                                    <Button onClick={()=>{this.setState({changePwVisible: !this.state.changePwVisible})}} className='myinfoBtn'>
                                        {this.state.changePwVisible ? string.close : string.change}
                                    </Button>
                                    {this.state.changePwVisible && 
                                        <Button onClick={()=>{this.onChangePassword()}} className='myinfoBtn saveBtn'>
                                            {string.saveData}
                                        </Button>                        
                                    }                                                          
                                </div>  
                            </div>
                            {this.state.changePwVisible && (
                            <>
                                <div className="myinfo-wrap sub">
                                    <div className='myinfo-subTitle'>{string.nowPw}</div>                           
                                    <Input 
                                        onChange={(e)=>{this.setState({password: e.target.value})}}                                       
                                        type='password'
                                        placeholder={string.inputCurrentPassword}
                                        className="myinfo-input"/>                                                                          
                                </div>
                                <div className="myinfo-wrap sub">
                                    <div className='myinfo-subTitle'>{string.newPw}</div>                           
                                    <Input 
                                        onChange={(e)=>{this.setState({newPassword: e.target.value})}}      
                                        type='password'                            
                                        placeholder={string.inputNewPassword}     
                                        className="myinfo-input"/>                                                                          
                                </div>
                            </>
                            )}

                            <Divider />   

                    {/* 보안 비밀번호 변경 */}
                            <div className="myinfo-wrap">
                                <div className="main-text">{string.securityPw}</div>                        
                                <div>
                                    <Button onClick={()=>{this.setState({changeSecPwVisible: !this.state.changeSecPwVisible})}} className='myinfoBtn'>
                                        {this.state.changeSecPwVisible ? string.close : string.change}
                                    </Button>
                                    {this.state.changeSecPwVisible && 
                                        <Button onClick={()=>{this.onChangeSecPassword()}} className='myinfoBtn saveBtn'>
                                            {string.saveData}
                                        </Button>                        
                                    }                                                          
                                </div>                                                  
                            </div>
                            {this.state.changeSecPwVisible && (
                            <>
                                <div className="myinfo-wrap sub">
                                    <div className='myinfo-subTitle'>{string.nowPw}</div>                           
                                    <Input 
                                        onChange={(e)=>{this.setState({password: e.target.value})}}                                       
                                        type='password'
                                        placeholder={string.inputCurrentSecPassword}
                                        className="myinfo-input"/>                                                                          
                                </div>
                                <div className="myinfo-wrap sub">
                                    <div className='myinfo-subTitle'>{string.newPw}</div>                           
                                    <Input 
                                        onChange={(e)=>{this.setState({newSecPassword: e.target.value})}}      
                                        type='password'                           
                                        placeholder={string.inputNewSecPassword}      
                                        className="myinfo-input"/>                                                                          
                                </div>
                            </>
                            )}
                            <Divider />

                    {/* OTP 설정 */}
                            <div className="myinfo-wrap">
                                <div className="main-text">{string.setupOtp} 
                                    <span style={{cursor:'pointer'}} onClick={()=> {Modal.info({content: string.myinfoTwoSecurityInfo})}}> <ExclamationCircleOutlined /></span>
                                </div>                            
                                <Switch defaultChecked={this.state.onOtp} value={this.state.onOtp} onChange={()=>{ this.onClickOtp() }} disabled={this.state.onOtp}/>
                            </div>
                            {this.state.onOtpVisible && (
                                <>
                                <div className='myinfo-wrap sub align-m'>
                                    <div className='myinfo-otp'> 
                                        <div className='myinfo-otp-box'>
                                            <MailOutlined className="mailIcon"/>
                                            {string.myInfoOtpMail}
                                        </div>        
                                        <Input 
                                            onChange={(e)=>{this.setState({verifyCode: e.target.value})}}                                                                      
                                            className="myinfo-input2"/>                           
                                    </div>
                                    <Divider type="vertical" className='dividerVertical'/>
                                    <div className='myinfo-otp'> 
                                        <div className='myinfo-otp-box'>
                                            <QRCode                                    
                                                size={160}
                                                color='#000'
                                                value={this.state.otpKeyUrl}     
                                                name="타이거월렛"
                                            />
                                            <span className='myinfo-otykey'>{this.state.otpKey}<span><CopyOutlined onClick={()=>{this.onCopyCode();}}/></span></span>
                                        </div>
                                        <Input 
                                            onChange={(e)=>{this.setState({authCode: e.target.value})}}                                                                      
                                            className="myinfo-input2"/>
                                    </div>
                                </div>
                                <div className='myinfo-otp'>                             
                                    <Button className='myinfoBtn saveBtn' onClick={()=>{this.verifyOtp()}}>{string.submit}</Button>  
                                </div>
                                </>
                            )}

                    {/* OTP 변경 */}
                            <div className="myinfo-wrap sub">
                                <div className='myinfo-subTitle'>{string.changeOtp}</div>                               
                                <div>
                                    <Button onClick={()=>{this.setState({modifyOtpVisible: !this.state.modifyOtpVisible})}} className='myinfoBtn'>
                                        {this.state.modifyOtpVisible ? string.close : string.change}
                                    </Button>                                                                                       
                                </div> 
                             </div>         
                             {this.state.modifyOtpVisible && (
                                <>
                                <div className="myinfo-wrap sub">
                                    <div className='myinfo-subTitle'>{string.phone}</div>            
                                    <div className='myinfo-inputBox'>               
                                        <Input 
                                            onChange={(e)=>{
                                                let text = e.target.value.replace(/[^0-9]+/g,'');
                                                this.setState({phone: text})
                                            }}                                                
                                            placeholder={string.inputPhone}                                 
                                            className="myinfo-input3"/>        
                                        <button onClick={()=>this.onPressSendCertiCode()} className="'myinfoBtn sub">
                                            {this.state.certiVerified ? string.completed : (this.state.onTimer ? this.state.timer : string.sendCertiCode2)}
                                        </button>                                                                  
                                    </div>
                                </div>
                                <div className="myinfo-wrap sub">
                                    <div className='myinfo-subTitle'>{string.certiCode}</div>                           
                                    <div className='myinfo-inputBox'>               
                                        <Input 
                                            onChange={(e)=>{
                                                let text = e.target.value.replace(/[^0-9]+/g,'');
                                                this.setState({certiCode: text})
                                            }}                                            
                                            placeholder={string.inputCertiCode}                                 
                                            className="myinfo-input3"/>        
                                        <button onClick={()=>this.onPressCheckCertiCode()} className="'myinfoBtn sub">
                                            {this.state.certiVerified ? string.completed : string.checkCertiCode}
                                        </button>                                                                  
                                    </div>                                                                  
                                </div>
                                </>
                             )}          
                            <Divider />

                    {/* KYC 인증 */}
                            <div className="myinfo-wrap">
                                <div className="main-text">{string.certiKYC}</div>  
                                <div className='myinfo-kycStatus'>
                                    {(this.props.userinfo.securityLevel == 3 || this.state.kycStatus == 1 ) ? (
                                    <span>{string.certiCompleted}</span>
                                    ) : (this.state.kycStatus == 0 ? (
                                    <span>{string.auditing}</span>
                                    ) : (
                                    <Button        
                                        className="myinfoBtn"                               
                                        onClick={() => {
                                        if (this.props.userinfo.securityLevel != 2 ) {
                                            Modal.error({content: string.kycOTPerror})
                                        }                                       
                                        this.setState({kycVisible: !this.state.kycVisible})                                        
                                    }}>
                                        신청하기
                                    </Button>
                                    ))}
                                </div>                                              
                            </div>
                            { this.state.kycVisible && (
                                <div>
                                    <div className="myinfo-otp">
                                        <div className='myinfo-fileTitle' style={{padding:0}}>{string.kycInfo}</div>                           
                                        <Input                                                                     
                                            type="file"
                                            accept="image/*;capture=camera"          
                                            onChange={e => {
                                                this.actionImgCompress(e.target.files[0]);                                                
                                            }}                           
                                            className="myinfo-input file"/>                                                                                                                                         
                                        <Button className='myinfoBtn saveBtn' onClick={(()=>{this.regist()})}>{string.submit}</Button>  
                                    </div>     
                                 
                                </div>                                
                            )}   
                        </div>                   
                    </div>
                </div>
            </div>

            // </div >

        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string,
        isLogin: state.loginReducer.isLogin,
        userinfo: state.loginReducer.userinfo,
        coinList: state.coinListReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLogin: userinfo => {
            dispatch(setLogin(userinfo));
        },  
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Mypage));