import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React from 'react';
import "../css/signUp.scss";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto from 'crypto';
import { connect } from "react-redux";
import { stringReducer } from "../actions";
import { Route, withRouter } from "react-router-dom";

class SignUp extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            emailDuplCheck: false,
            certiVerified: false,
            phoneEditable: true,
            timer: 180,
            onTimer: false,
            visible: false,
            recommendCode: this.props.match.params.recommendCode,
            resendEmailVisible: false,
            resendEmailAddress: '',
            resendEmailError: '',
        };
    }
    componentDidMount() {
    }
    onPressEmailDuplCheck = () => {
        const { language, string } = this.props;
        if (this.state.emailDuplCheck) return;

        httpGet(httpUrl.userCheckEmail, [this.formRef.current.getFieldValue("email")]).then((result) => {
            console.log("## onPressEmailDuplCheck result: " + JSON.stringify(result));
            result = result.data;

            if (result == "SUCCESS") {
                this.formRef.current.setFields([{
                    name: 'email',
                    errors: []
                }]);
                this.setState({ emailDuplCheck: true, msg: '' });

            } else {
                console.log("## onPressEmailDuplCheck error");

                let message = string.emailDuplCheckError;
                if (result == 'INVALID_EMAIL') message = string.emailDuplCheckErrorInvalidEmail;
                if (result == 'ALREADY_EXIST') message = string.emailDuplCheckErrorEmailDupl;

                this.formRef.current.setFields([{
                    name: 'email',
                    errors: [message]
                }]);
            }

        }).catch(e => {
            console.log("## userPhoneauthCheck error: " + e)
        });

    }

    onPressSendCertiCode = () => {
        const { language, string } = this.props;
        const form = this.formRef.current;
        const formPhone = form.getFieldValue("phone").replace(/[^0-9]+/g,'');
        form.setFields([{ name: 'phone', value: formPhone }]);

        if (this.state.certiVerified) return;
        if (this.state.onTimer) return;

        if (formPhone == undefined || formPhone == '' || formPhone.length < 9) {
            form.setFields([{
                name: 'phone',
                errors: [string.signUpRuleMsg08]
            }]);
            return;
        }
        httpPost(httpUrl.userPhoneauthGenerate, [], { phone: formPhone }).then((result) => {
            console.log("## userPhoneauthGenerate result: " + JSON.stringify(result));
            result = result.data.token;
            this.certiCodeToken = result;
            if (result && result != '') {
                this.setState({ onTimer: true, phoneEditable: false });
                this.interval = setInterval(
                    () => {
                        if (this.state.timer == 0) {
                            clearInterval(this.interval);
                            this.setState({ onTimer: false, timer: 180 });
                            return;
                        }
                        this.setState({ timer: this.state.timer - 1 });
                    },
                    1000
                );
            } else {
                form.setFields([{
                    name: 'phone',
                    errors: [string.certiCodeSendFail]
                }]);
                console.log("## userPhoneauthGenerate server error");
            }

        }).catch(e => {
            console.log("## userPhoneauthGenerate error: " + e)
        });
    }

    onPressCheckCertiCode = () => {
        const { language, string } = this.props;
        if (this.state.certiVerified) return;
        if (!this.state.onTimer) return;

        const form = this.formRef.current;
        const formPhone = form.getFieldValue("phone").replace(/[^0-9]+/g,'');
        const formCertiCode = form.getFieldValue("certiCode");

        if (formCertiCode == undefined || formCertiCode == '') {
            form.setFields([{
                name: 'certiCode',
                errors: [string.certiCodeFail]
            }]);
            return;
        }

        httpGet(httpUrl.userPhoneauthCheck, [this.certiCodeToken, formCertiCode], {}).then((result) => {
            console.log("## userPhoneauthCheck result: " + JSON.stringify(result));
            result = result.data;

            if (result.responseCode == "SUCCESS") {
                this.setState({ certiVerified: true });
            } else {
                console.log("## userPhoneauthCheck server error");

                let message = string.certiCodeWrong;
                if (result.responseCode == 'EXPIRED') message = string.certiCodeExpired;

                form.setFields([{
                    name: 'certiCode',
                    errors: [message]
                }]);
            }

        }).catch(e => {
            console.log("## userPhoneauthCheck error: " + e)
        });
    }

    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    handleSubmit = (values) => {
        const { language, string } = this.props;
        console.log('handleSubmit');
        const form = this.formRef.current;

        console.log('handleSubmit2');
        let password = form.getFieldValue("password") + form.getFieldValue("email");
        let secPassword = form.getFieldValue("securityPassword") + form.getFieldValue("email");
    
        let passwordHash = crypto
        .createHash('sha256')
        .update(password, 'utf8')
        .digest()
        .toString('hex');
        
        let secPasswordHash = crypto
        .createHash('sha256')
        .update(secPassword, 'utf8')
        .digest()
        .toString('hex');

        httpPost(httpUrl.signUp, [], {
            deviceId: 'web',
            id: form.getFieldValue("email"),
            mnemonic: 'web',
            password: passwordHash,
            name: form.getFieldValue("name"),
            phone: form.getFieldValue("phone"),
            recommender: this.state.recommendCode,
            securityPassword: secPasswordHash,
    
        }).then((result)=>{
            console.log("## signUp result: " + JSON.stringify(result));
            result = result.data;      
            
            if (result.result){
                this.props.history.push('/signupok');
            } else {
                let message = string.signUpErrorMessage;
                if(result.reason == 'DUPLICATE') message = string.signUpErrorIdDuplication;
                else if(result.reason == 'WRONG_EMAIL_TYPE') message = string.signUpErrorInvalidEmailType;
                else if(result.reason == 'MAX_PHONE') message = string.signUpErrorMaxPhone;
                
                Modal.info({
                    title: string.signUpFailTitle,
                    content: (
                    <div className="signup-result">
                        {message}
                    </div>
                    ),
                    onOk() {},
                });
            }
        
        }).catch(e=>{
        console.log ("## signUp error: " + e)
        });
    }

    agreementModal = () => {
        const { language, string } = this.props;
        Modal.info({
          width: 500,
          title: string.policyTitle,
          content: (
            <div className="agreement-content">
                {string.policyContent.map((value, index) => {
                    let result = [];
                    if (value.title)
                        result.push(
                        <div key={'title_' + index} className="agreement-content-title">
                            {value.title}
                        </div>
                        );
                    if (value.content)
                        result.push(
                        <div key={'content_' + index} className="agreement-content-body">
                            {value.content}
                        </div>
                        );
                    return result;
                })}
            </div>
          ),
          onOk() {},
        });
      }

    resendEmail = () => {
        const { language, string } = this.props;
        
        if (this.state.resendEmailAddress == null) {
            this.setState({resendEmailError: string.emailRule03})
            return;
        }
        let resendEmailAddress = this.state.resendEmailAddress.trim()
        if (resendEmailAddress == '') {
            this.setState({resendEmailError: string.emailRule03})
            return;
        }
        var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        if (!regExp.test(resendEmailAddress)) {
            this.setState({resendEmailError: string.emailRule03})
            return;
        }
        httpGet(httpUrl.userResendEmailSignup, [resendEmailAddress]).then((result)=>{
            result = result.data;
            if (result == "SUCCESS"){
                this.setState({resendEmailVisible: false}, ()=>{
                    Modal.info({
                        content: (
                        <div className="signup-result">
                            {string.emailResent}
                        </div>
                        ),
                        onOk() {},
                    });
                })
            } else {
                let message = string.emailResendFailed;
                if (result == "INVALID_EMAIL") message = string.invalidEmail;
                if (result == "ALREADY_VERIFIED") message = string.emailAlreadyVerified;
                this.setState({resendEmailVisible: false}, ()=>{
                    Modal.info({
                        content: (
                        <div className="signup-result">
                            {message}
                        </div>
                        ),
                        onOk() {},
                    });
                })
            }
      
        }).catch(e=>{
            console.log ("## resendEmail error: " + e);
        });

    }
    render() {
        const { language, string } = this.props;
        let { emailDuplCheck, phoneEditable, certiVerified } = this.state;

        return (
            <div className="wrapper">
                <div className="BT-HD-right-container">
                    <div className={`BT-HD-right-element ${language=='ko' ? 'color-blue' : ''}`} onClick={()=>this.props.setLanguage('ko')}>
                        KO
                    </div>
                    <div className={`BT-HD-right-element ${language=='en' ? 'color-blue' : ''}`} onClick={()=>this.props.setLanguage('en')}>
                        EN
                    </div>
                    <div className={`BT-HD-right-element ${language=='ja' ? 'color-blue' : ''}`} onClick={()=>this.props.setLanguage('ja')}>
                        JA
                    </div>
                </div>
                <div className="header">
                    {string.welcome}
                </div>
                <div className="container" style={{ textAlign: 'center' }}>

                    <div className="menu-line" />
                    <div className="login-form-wrapper">
                        <div>
                            <div className="login-text-login">

                            </div>
                            <div className="login-text-sub">
                            </div>
                        </div>
                        <Form onFinish={this.handleSubmit} className="form" ref={this.formRef}>
                            <div className="signup-form">
                                <div className="login-field-icon">
                                    <img src={require('../img/login/id.png')} />
                                </div>
                                <div className="overlap-button" onClick={this.onPressEmailDuplCheck}>
                                    {this.state.emailDuplCheck ? string.completed : string.dupCheck}
                                </div>
                                <div className="login-field-input">
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || value === '') return Promise.reject(string.signUpRuleMsg09);
                                                    else if (!emailDuplCheck) return Promise.reject(string.needEmailDuplCheck);
                                                    else return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input className="input22" type="email"
                                            placeholder={string.idEmail}
                                            disabled={this.state.emailDuplCheck}
                                        />
                                    </Form.Item>
                                </div>

                                <div className="login-field-icon">
                                    <img src={require('../img/login/password.png')} />
                                </div>
                                <div className="login-field-input">
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: string.signUpRuleMsg10
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    let strongRegex = new RegExp(
                                                        '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,15}$'
                                                    );
                                                    if (!strongRegex.test(value)) return Promise.reject(string.signUpRuleMsg07);
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input type="password" className="input2"
                                            placeholder={string.passwordRule}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="login-field-icon">
                                    <img src={require('../img/login/password_confirm.png')} />
                                </div>
                                <div className="login-field-input">
                                    <Form.Item
                                        name="passwordConfirm"
                                        rules={[
                                            {
                                                required: true,
                                                message: string.signUpRuleMsg11
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(string.signUpRuleMsg05);
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input className="input2" type="password" placeholder={string.passwordConfirm}/>
                                    </Form.Item>
                                </div>
                                <div className="login-field-icon">
                                    <img src={require('../img/login/name.png')} />
                                </div>
                                <div className="login-field-input">
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: string.signUpRuleMsg12
                                            },
                                        ]}
                                    >
                                        <Input className="input2" placeholder={string.name} />
                                    </Form.Item>
                                </div>
                                <div className="login-field-icon">
                                    <img src={require('../img/login/call.png')} />
                                </div>
                                <div className="login-field-input">
                                    <Form.Item
                                        name="phone"
                                        rules={[
                                            {
                                                required: true,
                                                message: string.signUpRuleMsg13
                                            },
                                        ]}
                                    >
                                        <Input type="phone" className="input22" placeholder={string.inputTel} disabled={!phoneEditable} />
                                    </Form.Item>
                                </div>

                                <div className="login-field-icon">
                                    <img src={require('../img/login/password.png')} className="down" />
                                </div>
                                <div className="login-field-input">
                                    <Form.Item
                                        name="securityPassword"
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || value.length != 4) return Promise.reject(string.signUpRuleMsg14);
                                                    return Promise.resolve();
                                                },
                                            }),

                                        ]}
                                    >
                                        <Input className="input2" type="number" placeholder={string.inputSecurity} maxLength={4} onInput={this.maxLengthCheck} />

                                    </Form.Item>
                                </div>
                                <div className="login-field-icon">
                                    <img src={require('../img/login/password_confirm.png')} />
                                </div>
                                <div className="login-field-input">
                                    <Form.Item
                                        name="securityPasswordConfirm"
                                        rules={[
                                            {
                                                required: true,
                                                message: string.signUpRuleMsg14
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue('securityPassword') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(string.signUpRuleMsg15);
                                                },
                                            }),
                                        ]}>
                                        <Input className="input2" type="number" placeholder={string.inputSecurityConfirm} maxLength={4} onInput={this.maxLengthCheck} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div>
                                <div className="agree" >
                                    {/* <div className="agree-link" onClick={this.agreementModal}>{string.policyView}</div> */}
                                    <Form.Item name="useAgreement" valuePropName="checked" initialValue={false} className="chkbox" 
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value) return Promise.resolve();
                                                    return Promise.reject(string.needAgreement);
                                                },
                                            }),
                                        ]}>
                                        <Checkbox className="agree-content" onChange={(e) => {
                                            if (e.target.checked) this.agreementModal()
                                        }}> {string.policyTitle2} </Checkbox>
                                    </Form.Item>
                                </div>

                                <div className="signupbtn">
                                    <Form.Item>
                                        <Button className="signup-btn signup-btn-ok" type="primary" htmlType="submit">{string.signUp}</Button>
                                    </Form.Item>
                                </div>

                                <div>
                                    {string.emailResendGuide1}<br/>
                                    {string.emailResendGuide2}
                                </div>
                                <div className="signupbtn">
                                    <Form.Item>
                                        <Button className="signup-btn resend-btn-ok" onClick={()=>this.setState({resendEmailVisible: true})}>{string.emailResend}</Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
                <Modal
                    title={string.emailResend}
                    visible={this.state.resendEmailVisible}
                    onOk={this.resendEmail}
                    onCancel={()=>this.setState({resendEmailVisible: false})}
                    okText={string.ok}
                    cancelText={string.cancel}>
                    <div style={{paddingBottom: '10px'}}>
                        {string.inputJoinEmail}
                    </div>
                    <div>
                        <Input className="input22" type="email"
                            placeholder={string.idEmail}
                            onChange={(e)=>{this.setState({resendEmailAddress: e.target.value})}}
                            value={this.state.resendEmailAddress}
                        />
                    </div>
                    <div style={{paddingBottom: '5px', color: 'red'}}>
                        {this.state.resendEmailError}
                    </div>
                </Modal>
            </div >
        );
    }
}

const mapStateToProps = state => {
  return {
    language: state.stringReducer.language,
    string: state.stringReducer.string
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguage: lan => {
      dispatch(stringReducer(lan));
    }
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));