import { Modal } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import { setLogout } from "../actions";
import Const from "../const";

class Top extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  logout = () => {
    const { language, string, setLogout, history } = this.props;
    Modal.confirm({
        title: string.myinfoLogout,
        content: string.myinfoLogoutConfirm,
        okText: string.ok,
        cancelText: string.cancel,
        onOk() {
            setLogout();
            history.push('/');
        },
        onCancel() {
        },
    });
  }

  main = () => {
    this.props.history.push('/Currency');
  } 

  render () {
    const { language, string } = this.props;
    return(
      <>
        <div className="BT-HD-right-container">
          <div className={`BT-HD-right-element ${language == 'ja' ? 'color-blue' : ''}`} onClick={this.logout}>
              LOGOUT
          </div>
        </div>
        <div className="header" onClick={this.main}>
          {/* {string.welcome} */}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
      language: state.stringReducer.language,
      string: state.stringReducer.string,
      // isLogin: state.loginReducer.isLogin,
      // userinfo: state.loginReducer.userinfo,
      // coinList: state.coinListReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return {
      setLogout: lan => {
          dispatch(setLogout());
      },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Top));