import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React from 'react';
import "../css/signUp.scss";

import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { stringReducer, setLogin, setLogout } from "../actions";
import { Link, Route, withRouter } from "react-router-dom";
import { formatBalance } from "../util/coinUtil";
import string from '../language/StringKo';
import con from '../const';

const FormItem = Form.Item;

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blockchains: [],
            balances: [],
        };
    }
    componentDidMount() {
        this.requestCurrencyExchangeRate()
    }

    requestCurrencyExchangeRate = (hide) => {
        if (con.enableForeignExchangeRate) {
            console.log("##### Request currencyExchangeRate")
            return new Promise((resolve, reject) => {
                httpGet(httpUrl.currencyExchangeRate, [], {}, hide).then((result) => {
                    console.log("## Request currencyExchangeRate loaded")
                    result = result.data;
                    global.exchangeRate = [
                        1,
                        result.find(x => x.currency == 'KRWUSD').rate,
                        result.find(x => x.currency == 'KRWCNY').rate,
                        result.find(x => x.currency == 'KRWJPY').rate,
                    ]
                    this.state.exchangeRateLoaded = true;
                    resolve();
                }).catch(e => {
                    console.log("## requestCurrencyExchangeRate error: " + e)
                    reject();
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                this.state.exchangeRateLoaded = true;
                global.exchangeRate = [1, 1, 1, 1];
                resolve();
            })
        }
    }

    render() {
        const { language, string } = this.props;
        const { close } = this.state;


        const menus = [
            {
                idx: 1,
                name: " 시세",
                url: "/Currency",
            },
            {
                idx: 2,
                name: "내지갑",
                url: "/MyWallet",
            },
            {
                idx: 3,
                name: "교환",
                url: "/Exchange",
            },
            {
                idx: 4,
                name: "채굴",
                url: "/Mining",
            },
            {
                idx: 5,
                name: "공지사항",
                url: "/notice",
                parent: "/Currency",

            },
            {
                idx: 6,
                name: "지갑상세",
                url: "/myWalletDialog",
                parent: "/MyWallet",

            },
            {
                idx: 7,
                name: "지갑받기",
                url: "/walletReceive",
                parent: "/MyWallet",

            },
            {
                idx: 8,
                name: "지갑보내기",
                url: "/walletSend",
                parent: "/MyWallet",

            },
            {
                idx: 9,
                name: "내정보",
                url: "/mypage",               
            },
        ];
        const menuShow = menus.filter(x => x.idx <= 2 || x.idx > 8)
        const currentPage = menus.find(
            (x) => x.url === this.props.history.location.pathname
        );
        // console.log(this.props.userinfo)

        return (

            <div className="header-container">
                <div className="top-navi">
                    <div>
                        {menuShow.map((row) => {
                            return (
                                <div
                                    key={row.idx}
                                    onClick={() => this.props.history.push(row.url)}
                                    className={
                                        "top-menu " + (row.idx === currentPage.idx || row.url === currentPage.parent ? "active" : "")
                                    }
                                >
                                    <div className="top-text">   {row.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

        )
    }
}

export default withRouter(Header);