import { Form, Input, Checkbox, Button, Modal, Divider, Image } from 'antd';
import React from 'react';
import "../css/signUp.scss";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { stringReducer, setLogin, setLogout } from "../actions";
import { Link, Route, withRouter } from "react-router-dom";
import { formatBalance } from "../util/coinUtil";
import string from '../language/StringKo';
import Header from '../components/Header';
import mining from '../img/mining/mining-bg.png';
import Coin from '../lib/coin/coin';
import NumberFormat from '../lib/util/numberformat';

const FormItem = Form.Item;

class MiningDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }

    componentDidMount() {
       
    }
    componentWillUnmount() {
       
    }


    render() {
        console.log(this.props.userinfo)
        const { language, string } = this.props;
        return (
            <div className="wrapper">
                <div className="BT-HD-right-container">
                    <div className={`BT-HD-right-element ${language == 'ja' ? 'color-blue' : ''}`} onClick={this.logout}>
                        LOGOUT
                    </div>
                </div>
                <div className="header">
                    {/* {string.welcome} */}
                </div>

                <div className="container">
                    <Header />

                    <div className="container-box" style={{ paddingBottom: '30px' }}>
        
                        {/* 컨텐츠 */}
                        dd
                        {/* 상단 이미지 부분 */}
                   
                    </div>
                </div>
            </div>

            // </div >

        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string,
        isLogin: state.loginReducer.isLogin,
        userinfo: state.loginReducer.userinfo,
        coinList: state.coinListReducer,
    };
};

export default withRouter(connect(mapStateToProps)(MiningDetail));