import { Form, Input, Checkbox, Button, Modal, Table, Divider, Pagination } from 'antd';
import React from 'react';
import "../../css/signUp.scss";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { stringReducer, setLogin, setLogout, setCoinList, setCoinBalance, setCoinCurrency } from "../../actions";
import { LeftSquareOutlined, } from '@ant-design/icons';
import { Link, Route, withRouter } from "react-router-dom";
import { formatBalance } from "../../util/coinUtil";
import string from '../../language/StringKo';
import moment from "moment";
import { comma } from '../../util/numberUtil';
import Header from '../../components/Header';
import Coin from '../../lib/coin/coin';
import WalletList from './WalletList';
import con from '../../const';
import Top from '../../pages/Top';
const FormItem = Form.Item;

class MyWalletDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: {
                total: 0,
                current: 1,
                pageSize: 5,
            },
            list: [],
            coinData: {
                image: "",
                name: "",
                symbol: "",
                balance: "",
            },
            coinType: this.props.location.state.coinType,
            walletDialogOpen: false,
            exchangeRate: global.exchangeRate || [],
            currency: global.currency,
            exchangeRateLoaded: false,
        }
    }

    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        }, () => this.getTradeList());
    };


    componentDidMount() {
        this.getTradeList();
        // this.getList()
        // console.log("123123123 " + this.state.coinType)
        // if(!data) {

        // }

        this.requestSystemListBlockchain();
        this.state.exchangeRate.length === 0 && this.requestCurrencyExchangeRate().then(() => {
            this.setState({ exchangeRate: global.exchangeRate })
        })
    }

    requestBalance = async () => {
        console.log("##### Request balance")
        httpGet(httpUrl.balance, [0], {}).then(async (result) => {
            result = result.data;
            console.log(result)
            await this.props.setCoinBalance(result);
            await this.getCoinList();
            // this.setState({ balances: result })
        }).catch(e => {
            console.log("## requestBalance error: " + e)
        });
    }
    requestSystemListBlockchain = () => {
        if (this.props.coinList.length > 0) {
            this.requestBalance();
        } else {
            console.log("## Loading systemListBlockchain");
            httpGet(httpUrl.systemListBlockchain, [this.props.language], {}).then(async (result) => {
                result = result.data;
                console.log('## systemListBlockchain loaded');
                result.forEach(obj => {
                    obj.status = true;
                    obj.address = '';
                    obj.balance = 0;
                    obj.balanceLoaded = false;
                    obj.price = 0;
                    obj.priceLoaded = false;
                    obj.value = 0;
                    obj.valueLoaded = false;
                    obj.purchaseFee = 0;
                    obj.sendInFee = 0;
                    obj.sendExFee = 0;
                    obj.tradeFee = 0;
                    obj.feeLoaded = false;
                    obj.serverWallet = '';
                    obj.serverWalletLoaded = false;
                    obj.change = 0.0;
                    obj.market = 0.0;
                    obj.trends = [];
                })
                await this.props.setCoinList(result);
                await this.requestCurrency();
                // this.setState({ blockchains: result })
            }).catch((e) => {
                console.log('## systemListBlockchain load error: ' + e)
            });
        }
    }


    getCoinList = async () => {
        this.setState({
            coinData: this.props.coinList.find(x => x.coinType === this.state.coinType),
        });
    }

    getTradeList = () => {
        let pageNum = this.state.pagination.current
        let pageSize = this.state.pagination.pageSize
        let coinType = this.props.location.state.coinType
        httpGet(httpUrl.transLog, [pageNum, pageSize, coinType]).then((result) => {
            result = result.data;

            let list = [];
            result.logs.forEach((obj, idx) => {
                const rowData = {};
                // rowData['transTimeString'] = obj.createDateString;
                rowData['transTimeString'] = moment(obj.createDate).format("YYYY-MM-DD HH:mm:ss");
                rowData['direction'] = obj.direction;
                rowData['type'] = obj.type;
                rowData['coinType'] = obj.coinType;
                rowData['status'] = obj.status;
                let targetAddr = (obj.userName ? '[' + obj.userName + ']' : '') + obj.targetAddr;//.substr(0,8)+'...'
                // let targetCnt = (obj.state == 1 ? obj.toCount : obj.fromCount);
                // (targetCnt > 1) && (targetAddr += util.format(string.extraCount, targetCnt - 1));
                rowData['targetAddr'] = targetAddr;
                rowData['amount'] = obj.amount;
                rowData['memo'] = obj.memo;
                rowData['sourceAddr'] = obj.from;
                rowData['fee'] = obj.fee;
                rowData['txId'] = obj.txid;
                rowData['destinationTag'] = obj.destinationTag;

                let stateBorderColor = '';
                let stateTextColor = '';
                let stateText = '';
                let titleText = '';
                titleText = rowData.targetAddr;
                if (rowData.direction == 'OUT') {
                    stateBorderColor =
                        rowData.status == 'SUCCESS' ? '#a7ceef' : '#2d67ff';
                    stateTextColor =
                        rowData.status == 'SUCCESS' ? '#4f95d0' : '#2d67ff';
                    stateText =
                        rowData.status == 'SUCCESS'
                            ? string.sent
                            : rowData.status == 'FAIL'
                                ? string.fail
                                : string.sending;
                    stateText = rowData.type == 'I2E' ? string.externalTrade + " " + stateText : stateText;
                    stateText = rowData.type == 'I2A' ? string.adjustAccount : stateText;
                } else {
                    stateBorderColor =
                        rowData.status == 'SUCCESS' ? '#f5bbbb' : '#2d67ff';
                    stateTextColor =
                        rowData.status == 'SUCCESS' ? '#e76969' : '#2d67ff';
                    stateText =
                        rowData.status == 'SUCCESS'
                            ? string.received
                            : rowData.status == 'FAIL'
                                ? string.fail
                                : string.receiving;
                    stateText = rowData.type == 'E2I' ? string.externalTrade + " " + stateText : stateText;
                    stateText = rowData.type == 'AIR' ? string.received : stateText;
                }
                rowData.stateBorderColor = stateBorderColor;
                rowData.stateTextColor = stateTextColor;
                rowData.stateText = stateText;
                rowData.titleText = titleText;

                list.push(rowData);
            })

            // console.log("!!!!! latest page, currenctPage:" + this.latestPageNum + ", " + this.currenctPageNum);
            // console.log("!!!!! rowData: " + JSON.stringify(tableData, null, 4));

            this.setState({
                list,
                pagination: { ...this.state.pagination, total: result.totalCount }
            },
                // () => this.state.list.push(
                //     { titleText: "0xeeE866E14eC60b0cC6eF150c69C307b5175f240B", stateText: "외부 출금중", amount: 1, transTimeString: "2022-10-11 15:43:21", stateBorderColor: '#a7ceef', stateTextColor: '#4f95d0' },
                //     { titleText: "[김민우김민우]0x324aa1D343Ae5e31c887ab0319Dba3264FfbEaeE", stateText: "외부 출금중", amount: 1, transTimeString: "2022-10-11 15:43:21", stateBorderColor: '#a7ceef', stateTextColor: '#4f95d0' },
                //     { titleText: "0xeeE866E14eC60b0cC6eF150c69C307b5175f240B", stateText: "외부 출금중", amount: 1, transTimeString: "2022-10-11 15:43:21", stateBorderColor: '#a7ceef', stateTextColor: '#4f95d0' },
                //     { titleText: "0xeeE866E14eC60b0cC6eF150c69C307b5175f240B", stateText: "외부 출금중", amount: 1, transTimeString: "2022-10-11 15:43:21", stateBorderColor: '#a7ceef', stateTextColor: '#4f95d0' },
                //     { titleText: "0xeeE866E14eC60b0cC6eF150c69C307b5175f240B", stateText: "외부 출금중", amount: 1, transTimeString: "2022-10-11 15:43:21", stateBorderColor: '#a7ceef', stateTextColor: '#4f95d0' },
                // )
            );
        }).catch(() => {
        })
    }

    setCoinType = (coinType) => {
        this.setState({
            coinType,
            walletDialogOpen: false
        }, () => {
            this.getCoinList();
            this.getTradeList();
        })
    }
    closeWalletList = () => {
        this.setState({
            walletDialogOpen: false
        })
    }
    calTotalValue() {
        let totalValue = 0;
        let allLoaded = true;
        this.props.coinList.forEach(coin => {
            allLoaded &= coin.valueLoaded;
            totalValue += coin.value;
        });
        // if (allLoaded) return totalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // else return '-';
        // return (totalValue * this.state.exchangeRate[this.state.currency]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (totalValue * this.state.exchangeRate[this.state.currency]).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    requestCurrency = (hide) => {
        console.log("## Request currency");
        let target
        let check
        return new Promise((resolve, reject) => {
            httpGet(httpUrl.currencies, [], {}, hide).then(async (result) => {
                // this.setState({ blockchains: this.state.blockchains},() => console.log("dddddddddddddddddddd " + JSON.stringify(this.state.blockchains)))
                this.props.setCoinCurrency(result.data);
                this.state.coinCurrencyUpdateTime = new Date().toLocaleTimeString();
                this.state.currencyLoaded = true;
                resolve();
                await this.requestBalance();
            }).catch(e => {
                console.log("## requestCurrency error: " + e);
                reject(e);
            });
        })
    }
    requestCurrencyExchangeRate = (hide) => {
        if (con.enableForeignExchangeRate) {
            console.log("##### Request currencyExchangeRate")
            return new Promise((resolve, reject) => {
                httpGet(httpUrl.currencyExchangeRate, [], {}, hide).then((result) => {
                    console.log("## Request currencyExchangeRate loaded")
                    result = result.data;
                    global.exchangeRate = [
                        1,
                        result.find(x => x.currency == 'KRWUSD').rate,
                        result.find(x => x.currency == 'KRWCNY').rate,
                        result.find(x => x.currency == 'KRWJPY').rate,
                    ]
                    this.state.exchangeRateLoaded = true;
                    resolve();
                }).catch(e => {
                    console.log("## requestCurrencyExchangeRate error: " + e)
                    reject();
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                this.state.exchangeRateLoaded = true;
                global.exchangeRate = [1, 1, 1, 1];
                resolve();
            })
        }
    }

    back = () => {
        this.props.history.goBack();
    }

    handlePagesChange = (current) => {
        this.setState(
            {
                pagination: {
                    ...this.state.pagination,
                    current,
                },
            },
            () => this.getTradeList()
        );
    };

    render() {
        const { language, string } = this.props;
        const columns = [
            {
                title: "구분",
                dataIndex: "titleText",
                className: "table-column-center",
            },
            {
                title: "상태",
                className: "table-column-center",
                dataIndex: "stateText",
                render: (data, row) => <div style={{ color: row.stateTextColor, borderColor: row.stateBorderColor }}>{data}</div>
            },

            {
                title: "수량",
                dataIndex: "amount",
                className: "table-column-center",
                render: (data) => <div>{(
                    data /
                    Coin.getCoinUnit(this.state.coinType).base
                ).toFixed(6)}</div>
            },
            {
                title: "일시",
                dataIndex: "transTimeString",
                className: "table-column-center",
            },

        ];


        const { close } = this.props;

        const data = this.props.coinList.find(x => x.coinType === this.state.coinType)
        return (
            <div className="wrapper">
                <Top />
                <div className="container">
                    <Header />
                    <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                        <div className="main-title">
                            {/* <div className="main-text">{string.tradeLog}</div> */}
                            <div className="back-btn" onClick={this.back}>
                                <LeftSquareOutlined style={{ fontSize: 30 }} />
                            </div>
                            <div className="more-btn2">
                                <Link to={{
                                    pathname: "/walletSend",
                                    state: {
                                        data: this.state.coinData
                                    }
                                }}>
                                    <Button>{string.walletSend}
                                    </Button>
                                </Link>
                            </div>
                            <div className="more-btn2">
                                <Link to={{
                                    pathname: "/walletReceive",
                                    state: {
                                        data: this.state.coinData
                                    }
                                }}>
                                    <Button>{string.walletReceive}
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <div className="charge-inner">

                            <div className="contentBlock">
                                {this.state.coinData.image &&
                                    <div className="content-box" onClick={() => {
                                        this.setState({
                                            walletDialogOpen: true
                                        })
                                    }}>
                                        <img
                                            src={require('../../img/common/dropdown_arrow.png')}
                                            style={{ width: 10, marginRight: 10 }}
                                        />
                                        <img src={this.state.coinData.image} />
                                    </div>
                                }
                                {/* <div className="content-box">{data && data.name}</div> */}
                                <div className="content-box">{this.state.coinData.symbol}</div>
                                {/* <div>{formatBalance(data.balance, data.decimal, 4)}</div> */}


                                {this.state.coinData.balance &&
                                    <>
                                        <div className="content-box blue"> {this.state.coinData.balance}</div>
                                        <div className="content-box"> {(this.state.coinData.value * this.state.exchangeRate[this.state.currency]).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {string.localCoinUnit[this.state.currency]}</div>
                                    </>
                                }
                            </div>

                        </div>
                        {
                            this.state.list.map((rowData, index) => {
                                let amount = (
                                    rowData.amount /
                                    Coin.getCoinUnit(this.state.coinType).base
                                ).toFixed(6)
                                return (
                                    <div className="trade-list" key={index}>
                                        <div style={{
                                            color: rowData.stateTextColor,
                                            // border: `1px ${rowData.stateBorderColor} solid`, 
                                            borderRadius: 4, fontWeight: 'bold'
                                        }}>{rowData.stateText} / {amount}</div>
                                        <div className="trade-title-text" style={{ color: "#686868" }}>{rowData.titleText}</div>
                                        <div>{rowData.transTimeString}</div>

                                    </div>
                                )
                            })
                        }
                        {
                            this.state.list.length === 0 &&
                            <div className="trade-nolist">거래내역이 없습니다</div>
                        }
                        {this.state.pagination.total > 0 &&
                            <div className="trade-pagination">
                                <Pagination
                                    defaultCurrent={1}
                                    total={this.state.pagination.total}
                                    onChange={this.handlePagesChange}
                                    pageSize={this.state.pagination.pageSize}
                                />
                            </div>
                        }
                        {/* <Table
                            rowKey={(record) => record.idx}
                            dataSource={this.state.list}
                            columns={columns}
                            pagination={this.state.pagination}
                            onChange={this.handleTableChange}
                        /> */}
                    </div>

                </div>
                {this.state.walletDialogOpen &&
                    <WalletList
                        coinType={this.state.coinType}
                        setCoinType={this.setCoinType}
                        close={this.closeWalletList}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string,
        isLogin: state.loginReducer.isLogin,
        userinfo: state.loginReducer.userinfo,
        coinList: state.coinListReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: lan => {
            dispatch(stringReducer(lan));
        },
        setLogin: userinfo => {
            dispatch(setLogin(userinfo));
        },
        setLogout: lan => {
            dispatch(setLogout());
        },
        setCoinList: coinList => {
            dispatch(setCoinList(coinList));
        },
        setCoinBalance: coinList => {
            dispatch(setCoinBalance(coinList));
        },
        setCoinCurrency: coinList => {
            dispatch(setCoinCurrency(coinList));
        },
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyWalletDialog));