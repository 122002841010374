import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React from 'react';
import "../css/signUp.scss";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto from 'crypto';
import { connect } from "react-redux";
import { stringReducer, setLogin, setLogout } from "../actions";
import { Route, withRouter } from "react-router-dom";

const FormItem = Form.Item;

class ModifyPassword extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
        let pathname = this.props.location.pathname.split("/");
        if (pathname.length != 4) this.props.history.push('/')
        this.token = pathname[3];
        if (!this.token || this.token == "") this.props.history.push('/')
    }
    handleSubmit = (values) => {
        const { language, string } = this.props;
        console.log('handleSubmit');

        values.token = this.token;

        console.log(values);
       
        httpPost(httpUrl.changePassword, [], values).then((result) => {
            result = result.data;

            if (result.result) {
                console.log("## success");
                this.formRef.current.resetFields();
                Modal.info({
                    title: string.passwordModify,
                    content: (
                        <div>
                            {string.changePasswordSuccess}
                        </div>
                    ),
                    onOk() { },
                });

            } else {
                console.log("## fail");
                Modal.info({
                    title: string.passwordModify,
                    content: (
                        <div>
                            {string.changePasswordFailed}
                        </div>
                    ),
                    onOk() { },
                });

            }
            // if (dlg != null) dlg.dismiss();

        }).catch(e => {
            // if (dlg != null) dlg.dismiss();

            console.log('## error: ' + e);
            Modal.info({
                title: string.passwordModify,
                content: (
                    <div>
                        {string.changePasswordFailed}
                    </div>
                ),
                onOk() { },
            });
            // if (callback instanceof Function) callback(false, '');
        });
    }
    render() {
        const { language, string } = this.props;
        return (
            <div className="wrapper">
                <div className="BT-HD-right-container">
                    <div className={`BT-HD-right-element ${language == 'ko' ? 'color-blue' : ''}`} onClick={() => this.props.setLanguage('ko')}>
                        KO
                    </div>
                    <div className={`BT-HD-right-element ${language == 'en' ? 'color-blue' : ''}`} onClick={() => this.props.setLanguage('en')}>
                        EN
                    </div>
                    <div className={`BT-HD-right-element ${language == 'ja' ? 'color-blue' : ''}`} onClick={() => this.props.setLanguage('ja')}>
                        JA
                    </div>
                </div>
                <div className="header">
                    {string.welcome}
                </div>
                <div className="container">
                    <div className="password-modify-title">{string.passwordModify}</div>
                    
                    <Form onFinish={this.handleSubmit} className="form" ref={this.formRef}>
                        <div>
                            <div className="login-field-icon">
                                <img src={require('../img/login/password.png')} />
                            </div>
                            <div className="login-field-input">
                                <Form.Item
                                    name="newPassword"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                let strongRegex = new RegExp(
                                                    '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,15}$'
                                                );
                                                if (!value || value == '') return Promise.reject(string.signUpRuleMsg10);
                                                else if (!strongRegex.test(value)) return Promise.reject(string.signUpRuleMsg07);
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input type="password" className="input2"
                                        placeholder={string.password}
                                    />
                                </Form.Item>
                            </div>

                        </div>
                        <div>

                            <div className="login-field-icon">
                                <img src={require('../img/login/password.png')} />
                            </div>
                            <div className="login-field-input">
                                <Form.Item
                                    name="passwordConfirm"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || value == '') return Promise.reject(string.signUpRuleMsg11);
                                                else if (getFieldValue('newPassword') != value) return Promise.reject(string.signUpRuleMsg05);
                                                else return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input type="password" className="input2"
                                        placeholder={string.passwordConfirm}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div>

                            <div className="signupbtn">
                                <Form.Item>
                                    <Button className="signup-btn signup-btn-ok" type="primary" htmlType="submit">{string.ok}</Button>
                                </Form.Item>
                            </div>
                        </div>

                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string,
        isLogin: state.loginReducer.isLogin,
        userinfo: state.loginReducer.userinfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: lan => {
            dispatch(stringReducer(lan));
        },
        setLogin: userinfo => {
            dispatch(setLogin(userinfo));
        },
        setLogout: lan => {
            dispatch(setLogout());
        },
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModifyPassword));