import React, { Component } from 'react';

import Content from "./Content";
import { Spin } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import { Route, Switch, withRouter } from "react-router-dom";
import { stringReducer, setLogin, setLogout, setCoinList } from "../actions";
import { connect } from "react-redux";
import Const from "../const";
import { bindActionCreators } from 'redux';

class Main extends Component {
  constructor(props) {
    super(props);
    this.userInfo = reactLocalStorage.getObject(Const.appCode + "#userinfo");
    if (this.userInfo && this.userInfo.idx) this.props.setLogin(this.userInfo);

    const coinlist = reactLocalStorage.getObject(Const.appCode + "#coinlist");
    if (coinlist instanceof Array) this.props.setCoinList(coinlist);
  }
  componentDidMount() {

    let pathname = this.props.location.pathname.split("/");

    if (pathname[1] !== "signup" && pathname[1] !== "password" && pathname[1] !== "privacy") {
      if (!this.userInfo.idx && this.props.location.pathname !== "/") {

        this.props.history.push("/");
      }
      if (this.userInfo.idx && this.props.location.pathname === "/") {
        this.props.history.push("/Currency");
      }
    }
  }
  render() {

    return (
      <>
        <div
          id="loadingSpinner"
          style={{
            display: "none",
            position: "fixed",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
            top: 0,
            left: 0,
            textAlign: "center",
            zIndex: 99999
            // pointerEvents: "none"
          }}>
          <Spin style={{ position: "absolute", top: "50%", zIndex: 99999 }} />
        </div>
        <Content />
      </>
    );
  }
}

// const mapDispatchToProps = dispatch => {
//   return {
//     setLogin: userinfo => {
//       dispatch(setLogin(userinfo));
//     },
//   };
// };
const mapDispatchToProps = dispatch => (
  bindActionCreators({ setCoinList, setLogin }, dispatch)
);
export default withRouter(connect(null, mapDispatchToProps)(Main));