const string = {
  completed: 'Completed',
  ok: 'OK',
  cancel: "Cancel",

  welcome: 'Welcome to Tiger Wallet!',
  localCoinUnit: ['KRW', 'USD', 'CNY', 'JPY'],

  /*********signUp**********/
  signUp: 'Membership sign up',
  signUpMsg01: 'The membership sign up request has been completed.',
  signUpMsg02: 'Authentication email has been sent to your subscribed ID (email).',
  signUpMsg03: 'You can log in after completing your email authentication.',
  signUpRuleMsg01: 'Already signed up by this email address.',
  signUpRuleMsg02: 'Email format is invalid',
  signUpRuleMsg03: 'Password complexity was not satisfied.',
  signUpRuleMsg04: 'An unknown error occurred during subscription process.',
  signUpRuleMsg05: 'The two passwords entered are different.',
  signUpRuleMsg06: 'Please accept the terms and conditions.',
  signUpRuleMsg07: 'Please enter 8-15 characters in combination of alphabets, numbers, and special characters.',
  signUpRuleMsg08: 'Please enter your phone number correctly.',
  signUpRuleMsg09: 'Please enter your email.',
  signUpRuleMsg10: 'Please enter your password.',
  signUpRuleMsg11: 'Please enter your password confirmation.',
  signUpRuleMsg12: 'Please enter your name.',
  signUpRuleMsg13: 'Please enter your phone number.',
  signUpRuleMsg14: 'Please enter 4 digits of your password.',
  signUpRuleMsg15: 'Please enter the same password.',

  signUpFailTitle: 'Member registration failed.',
  signUpFail: 'You failed to sign up.',
  signUpCancelMsg: 'Do you want to cancel membership sign up and go to the main page?',
  signUpAgreement: 'Collection of personal information and use',
  signUpOtherLoginMsg: '<span style="color:#00aab6; font-weight:bold;">Naver</span>or<span style="color:#00aab6; font-weight:bold;">Kakao</span> login allows <span style="color:#00aab6; font-weight:bold;">the use without a membership sign up.</span>',
  emailRule03: 'Please enter email format',
  cancle: 'Cancel',
  idEmail: 'ID (Email)',
  passwordRule: 'Password (eight to fifteen alphanumeric, special character combinations)',
  passwordConfirm: 'Password Confirmation',
  name: 'Name',
  inputTel: 'Please enter your phone number',
  inputSecurity: 'Password of 4 digits',
  inputSecurityConfirm: 'Check password',

  dupCheck: 'Check duplication',
  emailDuplCheckError: 'Email duplication check failed',
  emailDuplCheckErrorEmailDupl: 'This email is already registered.',
  emailDuplCheckErrorInvalidEmail: 'Email format is invalid',
  needEmailDuplCheck: 'Email duplication check is required.',

  sendCertiCode: 'Authentication request',
  certificateCodeFail: 'Please enter the authentication number.',
  certificateCodeWrong: 'You entered the wrong authentication number.',
  certiCodeSendFail: 'Failed to send authentication number.',
  certiCodeNotVerified: 'Phone number authentication is required.',

  signUpErrorTitle: 'Failed to generate a wallet',
  signUpErrorIdDuplication: 'This email is already registered. Please check your email.',
  signUpErrorInvalidEmailType: 'Email format is invalid. Please check again.',
  signUpErrorMaxPhone: 'You have exceeded the number of registered accounts per phone number.',
  signUpErrorMessage: 'Failed to generate a wallet. Please try again in a moment.',

  emailResent: 'Authentication email has been re-sent.',
  emailResendFailed: 'Failed to resend authentication mail.',
  invalidEmail: 'It is a wrong email.',
  emailAlreadyVerified: 'This email has already been certified.',
  inputJoinEmail: 'Please enter your registered email.',

  emailResendGuide1: 'Did you not receive the authentication email or did your authentication time expire?',
  emailResendGuide2: 'Click the resend authentication email button below to request an authentication again.',
  emailResend: 'Re-send an authentication email',

  /*********Trade Log**********/
  noCoins: 'No Trade Log',
  tradeLog: 'Trade Log',
  walletSend: 'Send',
  walletReceive: 'Receive',
  selectCoin: 'Please select coin.',
  copyComplete: 'Copy Completed',
  address: 'address',
  priceInsert: 'Insert Price',
  copyAddress: 'Copy Address',
  qrContainPrice: 'Price Contain In Qr',
  depositAddress: 'Deposit Address',
  insertAddress: "Insert Address",
  insertAmount: "Insert Amount",
  insertMemo: "memo(options)",
  securityPw: "Security Password",
  pleaseInsertAddress: "Please insert address.",
  pleaseInsertAmount: "Please insert amount.",
  pleaseInsertSecurityPw: "Please insert security password.",
  pleaseInsertOtp: "Please insert otp.",

  /********** Terms and Conditions, etc******/
  policyView: 'View Terms and Conditions',
  needAgreement: "Please accept the terms and conditions",
  policyTitle: 'Collection of personal information and terms of use',
  policyTitle2: '(Required) Collection of personal information and terms of use',
  policyContent: [
    { title: 'Chapter 1 General rules', content: '' },
    {
      title: 'Article 1 (Purpose)',
      content:
        'This TERMS AND CONDITIONS stipulates the rights, obligations and responsibilities between the company and the user, and other necessary matters regarding the use and procedures of the services provided by the apps and wallets of Hashone (hereinafter: the company).\n',
    },
    {
      title: 'Article 2 (Statement, explanation and TERMS AND CONDITIONS revision))',
      content: [
        '① The contents of the TERMS AND CONDITIONS shall be notified to the user by the company\'s service and other means, and the contents will be effective as the user agrees to this TERMS AND CONDITIONS.\n',
        '② The company may revise this TERMS AND CONDITIONS to the extent that it does not violate the relevant laws, such as the "Act on the Regulation of TERMS AND CONDITIONS" and the "Act on the Promotion of Information and Communications Network Utilization and Information Protection, etc.\n',
        '③ If the company revises the TERMS AND CONDITIONS, it will announce the date of application and the reason for revision, along with the current TERMS AND CONDITIONS, on the initial screen, pop-up screen, or notice of the company\'s site, seven days before the application date. However, in the event of a change in contents that is unfavorable to or significant to the user, the user is notified 30 days before the application date.\n',
        '④ Although the company publicly announced the revised TERMS AND CONDITIONS in accordance with the preceding paragraph and clearly notified the user that if the user does not express his/her intention within the period of seven days, the user\'s failure to express his/her intention explicitly shall be deemed that the user has agreed to the TERMS AND CONDITIONS revision.\n',
        '⑤ If the user expresses an explicit intention not accepting the application of the TERMS AND CONDITIONS revision, the company cannot apply the TERMS AND CONDITIONS revision. In this case, the user may terminate the agreement. However, the company may terminate the service agreement if there are special circumstances where the existing TERMS AND CONDITIONS cannot be applied.\n',
      ]
    },
    {
      title: 'Article 3 (Rules other than TERMS AND CONDITIONS)',
      content:
        'The company sets separate notices, individual TERMS AND CONDITIONS, individual agreements, usage guides, operation policies, detailed instructions, etc. (hereinafter collectively referred to as “Detailed Guidelines”) and may notify or obtain consent from the user. In the event that the contents of the Detailed Guidelines that have been agreed to by the user conflict with the TERMS AND CONDITIONS, the Detailed Guidelines take precedence. Any matters or interpretations not specified by this TERMS AND CONDITIONS will be governed by the Detailed Guidelines and related laws or commercial practices.\n',
    },
    {
      title: 'Article 4 (Definition of terms)',

      content: [
        '① The definition of terms used in the TERMS AND CONDITIONS is as follows.\n',
        '1. Cryptocurrency: It refers to currencies that exist electronically in the blockchain, such as Bitcoin (BTC) and Ethereum (ETH).\n',
        '2. User: It refers to the user who approved the TERMS AND CONDITIONS and registered a wallet provided by the company.\n',
        '3. App: It refers to a smartphone online-only program that can store or send and receive cryptocurrency.\n',
        '4. Wallet: It refers to an app that can store cryptocurrency on a smartphone.\n',
        '5. Password: It refers to a combination of characters set by the user to verify the identity between the user and the wallets and secure cryptocurrency.\n',
        '6. Recovery Words: It refers to 12-encrypted words for recovery when wallet is lost.\n',]
    },
    {
      title: 'Chapter 2 Service Use Application and Approval (User subscription and cancellation)',

      content: '',
    },
    {
      title: 'Article 5 (Establishment of an agreement for use)',

      content: [
        '① The user apply for the use of their wallets by expressing their consent to the TERMS AND CONDITIONS when creating wallets in the app provided by the company.\n',
        '② Use agreement is concluded by registered unit of wallet. Once the service agreement is established, the applicant will be registered as a wallet user.\n',
      ]
    },
    {
      title: 'Article 6 (Application for use)',
      content: 'Application for use is automatically approved when registering a wallet in an app provided by the company..\n',
    },
    {
      title: ' Article 7 (Consent to the use of user information and acceptance of application for use)',
      content: [
        '① Consent to the use of user information\n',
        '1. The company uses the user\'s personal information for the purpose of implementing this service agreement and providing services under this service agreement.\n',
        '2. The user information may be provided to companies affiliated with the company in order to make it easier for the user to use the company and its affiliated services. However, before providing the user information, the company shall notify the user of affiliates, the purpose of provision, and the details of the user information to be provided in advance and obtain the user\'s consent.\n',
        '3. The user can view and modify their personal information at any time by modifying the user information.\n',
        '4. If the user enters the user information in the application form and applies for use to the company pursuant to this TERMS AND CONDITIONS, it is deemed that the company agrees to collect, use and provide the user information listed in the application under the TERMS AND CONDITIONS.\n',

      ]
    },
    {
      title: ' Article 8 (Suspension and cancellation of use agreement)',
      content: [
        '① Cancellation of the use agreement and app use is possible by the user by deleting the app from the smartphone.\n',
        '② The company may not approve the application of the applicant for registration if there is a reason falling under each of the following subparagraphs, and if the reasons for each of the following subparagraphs are confirmed after signing up, the company may cancel the consent or terminate the use agreement.\n',
        '1. If user has previously lost membership under the TERMS AND CONDITIONS;\n',
        '2. If false or incorrect information is entered or provided, or the content presented by the company is not entered;\n',
        '3. If approval is not possible due to reasons attributable to the applicant for membership, or if the application is made in violation of all other matters prescribed by the TERMS AND CONDITIONS;\n',
        '4. If user wants to use the service for an illegal purpose or a separate business purpose;\n',
        '5. If the application is made for the purpose of violating related laws or impeding social order or morals\n',
        '6. In case the applicant\'s age is under 19;\n',
        '③ The company may request real name authentication or identity authentication through a professional organization within the range permitted by law in order to check whether the information provided by the member is consistent with the facts;\n',

      ]
    },
    { title: ' Chapter 3 Company\'s obligation', content: '' },
    {
      title: 'Article 9 Company\'s obligations for the user personal information and services',
      content: [
        '① The company complies with relevant laws and regulations and the TERMS AND CONDITIONS, and makes every effort to provide continuous and stable service.\n',
        '② The company may have a security system to protect personal information so that the user can safely use the service, and disclose and comply with the privacy policy.\n',
        '③ The company may write and use all or part of the personal information of the user as statistical data that cannot distinguish specific user information without prior consent of the user in relation to business, and for this purpose, the user transmits a cookie to the computer. However, if the user refuses to send cookies or change the settings of the device used to warn about the transmission of cookies, the use of the service may be changed without reasons attributable to the company due to the change in cookie settings.\n',
        '④ If the company deems that opinions or complaints raised by the user in relation to the use of the service are legitimate, they shall handle them, and can communicate the processing process and results to the user through bulletin boards and e-mails within the service.\n',
        '⑤ The company complies with laws and regulations related to the operation and maintenance of services such as the Information and Communication Network Act, the Location Information Protection Act, the Communication Secret Protection Act, and the Telecommunication Business Act.\n',

      ]
    },
    { title: ' Chapter 4 User\'s obligation', content: '' },
    {
      title: 'Article 10 (User\'s obligation to manage user wallets and passwords)',
      content: [
        '① The user is responsible for all management of the wallet and password. The user is responsible for all results arising from negligence or illegal use of the wallet and password registered by the user, and the company shall not be held liable for them. However, this is not the case if the company intentionally caused it.\n',
        '② 12 recovery words shall be written on paper and 12 recovery words are required to recover the lost wallet. Because the company does not have this information, it cannot be recovered if the recovery word is lost, and the user is fully responsible for it and the company is not responsible for it. In addition, the user is fully responsible for the loss caused by the leakage of 12 recovery words due to his/her mistake, and the company is not responsible for it.\n'

      ]
    },
    {
      title: 'Article 11 (Providing of information)',
      content: [
        '1. The company can provide information on the following services and products for convenience of use through wired and wireless telephones, e-mail, text messaging service (LMS/SMS), SNS, etc. In this case, the user can refuse to receive at any time.\n',
        '① Services related to events \n',
        '② Other services that the company frequently decide and provide to the user\n',
        '2. The user can view his/her personal information and request modification or change at any time through the service. The user shall modify the changes directly in the service or notify the company of the changes through the customer center if the details listed at the time of application for membership change. The company shall not be liable for any disadvantages caused by the user\'s failure to notify the company of the changes in the preceding paragraph.\n',

      ]
    },
    { title: 'Chapter 5 General rules for service use', content: '' },
    {
      title: 'Article 12 (Types of services)',
      content: [
        '① The services provided by the company include a wallet function to store cryptocurrency, and a function to send and receive cryptocurrency to other people. However, the user is responsible for the accuracy of the cryptocurrency wallet address provided by the user, and the company is not responsible for sending cryptocurrency to the cryptocurrency wallet address that the user incorrectly entered.\n',
        '② The type of service provided by the company may be changed from time to time due to the circumstances of the company, and the copyright and intellectual property rights for the provided service belong to the company.\n',
        '③ In relation to the service, the company only grants the user the right to use accounts, wallets, and services according to the terms of use set by the company, and the user cannot provide similar services and perform commercial activities using this.\n',
      ]
    },
    {
      title: 'Article 13 (Notice and Change of Service Contents)',
      content: [
        '① The company announces the characteristics, procedures, and methods of each service according to the type of service through the website, and the user shall understand and use the service notified by the company.\n',
        '② The company may change all or part of the individual services provided according to operational and technical needs if there is a considerable reason. In this case, the company notifies the user of the content at least seven days in advance.\n',
      ]
    },
    {
      title: 'Article 14 (Maintenance and suspension of service)',
      content: [
        '① In principle, the service shall be used 24 hours a day unless there is a special problem with the company\'s business or technology. However, this does not apply to the day or time set by the company prior to or after the need for regular inspection.\n',
        '② The company can divide the service into a certain range and set the available time separately for each range. In this case, the contents are notified in advance or later.\n',
        '③ The company can stop providing services in any of the following cases: In this case, the company will notify you in advance of the suspension of the service unless it is inevitable.\n',
        '1. Where unavoidable circumstances due to construction, such as repair of service equipment;\n',
        '2. Where the key telecommunications service provider as stipulated in the Telecommunications Business Act has stopped the telecommunication service;\n',
        '3. Where the service provided by an affiliate or a third party, other than a service provided directly by the company is suspended by a third party, such as an affiliate.\n',
        '4. Where there is any other force majeure reason;\n',
        '④ The company may restrict or suspend all or part of the service if normal service use is disrupted due to a national emergency, power failure, failure of the service facilities, or excessive use of the service.\n',
      ]
    },
    {
      title: 'Article 15 Restriction to use',
      content: [
        '① The company may restrict the members\' service use and access in any of the following cases:\n',
        '1. Where consecutive password and security password errors;\n',
        '2. Where hacking or accident occurs;\n',
        '3. Where theft of ID is suspected;\n',
        '4. Where a State agency requests restriction on services in accordance with relevant statutes;\n',
        '5. Where it is reasonably suspected that the user is involved in money laundering, unfair trade, criminal activities, etc.;\n',
        '6. Where the user who is a member is confirmed to be under 19 years of age;\n',
        '7. Where a cause corresponding to any other cause arises or measures are required to prevent such cause from occurring;\n',
        '② The company may restrict the member\'s use of send and receive in any of the following cases:\n',
        '1. Where the user who has joined as a member is confirmed to be under 19 years of age;\n',
        '2. Where hacking or fraud is suspected to have occurred;\n',
        '3. Where theft of ID is suspected;\n',
        '4. Where a State agency requests restriction on services in accordance with relevant statutes;\n',
        '5. Where it is reasonably suspected that the user is involved in money laundering, unfair trade, criminal activities, etc.;\n',
        '6. Upon request of the member himself/herself;\n',
        '7. Where the cryptocurrency has been sent but is not reflected in the member\'s account or that the send and the receive have been made differently from the actual send and receive request;\n',
        '8. Where a cause corresponding to any other cause arises or measures are required to prevent such cause from occurring;\n',
        '③ If the service is restricted or the agreement is terminated pursuant to this Article, the company shall notify the members by e-mail, SMS, or App Push.\n',
        '④ Members may file an objection to the restriction of use under this Article in accordance with the procedures set by the company. If the company acknowledges that the objection is justifiable, the company immediately resumes the use of the service.\n',

      ]
    },
    {
      title: 'Article 16 (Cancellation of Use Agreement)',
      content: [
        '① The user can apply for cancellation of the use agreement at any time through the customer center, and the company shall deal with it immediately in accordance with the relevant laws and regulations.\n',
        '② The company may limit the use of the service by setting a time when the following reasons occur to the member and request correction. Despite the request for correction, the agreement of use may be terminated if it is not corrected within a considerable period of time or if the same violation is repeatedly committed.\n',
        '1. Where the user violates the obligations of the user stipulated in Article 10 of this TERMS AND CONDITIONS or falls under the reasons for restriction of use stipulated in Article 15;\n',
        '2. Where violation of related laws, such as illegal communication and hacking in violation of the Act on Promotion of Information and Communication Network Utilization and Information Protection, etc. occurs;\n',
        '3. Where an act or attempt is made to interfere with the smooth progress of the service provided by the company;\n',
        '4. Where any other reason that cannot maintain this agreement due to reasons similar to each subparagraph.\n',
        '③ Upon cancellation of the use agreement pursuant to the preceding paragraph, all benefits obtained through the use of the service will lapse, and the company does not compensate for this.\n',
        '④ In the event of cancellation of the service use agreement pursuant to this Article, the company notifies the user by E-mail, SMS, App Push, etc.\n',
        '⑤ When the cancellation of the use agreement is completed, all information of the user except for the information that the company shall hold in accordance with the relevant laws and personal information processing policy will be deleted.\n',
        '⑥ If the company terminates the use agreement pursuant to Paragraph 2, the company may store the user\'s information for a certain period of time for receiving and processing the user\'s objection, and after that period elapses, deletes the user\'s information (except send and receive details).\n',
      ]
    },
    {
      title: 'Article 17 (Provision of information and posting of advertisements)',
      content: [
        '① In operating the service, the company can post various information related to the service on the service screen or provide it to the user through e-mail, SMS, and App Push.\n',
        '② In operating the service, the company may post various advertisements of the company or affiliates on the service screen or provide it to the user by e-mail, SMS, App Push, etc. with the consent of the user.\n',
      ]
    },
    { title: 'Chapter 5 Personal Information Protection', content: '' },
    {
      title: 'Article 18 (Protection of Personal Information)',
      content: [
        'The company strives to protect the user\'s personal information in accordance with the relevant laws and regulations. The user\'s personal information is used only for the purpose and scope agreed by the user for the smooth provision of services. The company does not provide the user\'s personal information to a third party unless it is in accordance with the law or the user agrees otherwise, and the details of this are determined in the privacy policy.\n',
      ]
    },
    { title: 'Chapter 6 Damage and Immunity Clause', content: '' },
    {
      title: ' Article 19 (Company\'s exemption and compensation)',
      content: [
        '① The company does not guarantee any matters not specified in this TERMS AND CONDITIONS regarding the service. Also, the company does not guarantee the value of the cryptocurrency that the company has not issued or guaranteed payment.\n',
        '② If the company is unable to provide the service due to natural disasters, DDoS attacks, IDC failures, server down due to soaring service access, and other unavoidable reasons, such as a line failure of a fixed-term carrier, the company is exempted from responsibility for providing the service. However, this does not apply to company\'s intentional or gross negligence.\n',
        '③ The company shall not be held responsible for any inevitable failure due to problems in the blockchain, defects in the cryptocurrency issue management system itself, technical problems, poor communication service providers, or regular server inspection. However, this does not apply to company\'s intentional or gross negligence.\n',
        '④ The company shall not be held liable for any service disruption or consequences caused by the cause attributable to the user. However, this does not apply if the user has a justifiable reason.\n',
        '⑤ The company is exempted from responsibility if it transacts with the service as a medium between users or between the user and third parties. However, this does not apply to company\'s intentional or gross negligence.\n',
        '⑥ The company shall not be held liable for the use of free services unless otherwise provided in the relevant laws.\n',
        '⑦ The company can perform regular, irregular, or urgent server checks to ensure stable service delivery.\n',
        '⑧ If anyone acquires or receives a third-party-owned cryptocurrency without authority due to a service error, computer failure, or other reasons, the company may take necessary measures, such as retrieving or restoring the cryptocurrency after prior notification to the parties.\n',

      ]
    },
    {
      title: ' Article 20 (Jurisdiction courts and compliance laws)',
      content: [
        '① The TERMS AND CONDITIONS using this service is regulated and interpreted in accordance with Korean law, and if a lawsuit is filed between the company and the user regarding the use of the service, such as the company\'s fee system, the Korean law applies.\n',
        '② Where a lawsuit between the company and the user regarding the use of the service is filed, the court according to the procedures prescribed in the statutes shall be the competent court.\n',

      ]
    },
    { title: 'Attachment', content: 'This TERMS AND CONDITIONS shall take effect as of 1 January 2020.' },
  ],

  ///////////////////////////////////////////////////////////////////////////////

  login: 'Login in',
  password: 'Password',

  loginFailedTitle: 'Login failed',
  loginFailedWrongId: 'Wrong ID.',
  loginFailedWrongPassword: 'Wrong password',
  loginFailedExpiredId: 'This ID has been disabled.',
  loginFailedEmailConfirm: 'Email is not certified. Please use the email after authentication.',
  loginFailedMessage: 'Login failed. Please try again in a moment.',
  loginFailedInvalidLoginInfo: 'The login information is invalid.',
  
  myinfoLogout: 'Logout',
  myinfoLogoutConfirm: 'Do you want to log out?',
  passwordModify: 'Change password',
  changePasswordFailed: 'Password change failed',
  changePasswordSuccess: 'The password change is complete. Please proceed with login.',


    /*********내 정보************/
    myPage:'My Information',
    phone:'Phone number',
    setupOtp: 'setting OTP',
    changeOtp: 'Change OTP',
    certiKYC:'KYC authentication',
    change: 'To change',
    close:'Close',
    saveData:'Save',
    nowPw:'Current password',
    newPw: 'New password',
    changePasswordFailedSamePassword: 'The old password and the new password are the same.',
    changePasswordFailedInvalidPassword: 'The password is incorrect.',
    myinfoTwoSecurityInfo: 'The double authentication key is a six-digit key that strengthens security when withdrawing from your wallet. Please download Google OTP or Authy from the App Store.',
    myInfoOtpMail : 'Please enter 6 digits of the authentication code sent by email.',
    otpCopied: 'OTP key copied successfully.',
    submit:'To submit',
    inputPhone: 'Enter Phone Number',
    sendCertiCode2: 'Send authentication number',
    phoneFail: 'Please enter your cell phone number correctly.',
    checkCertiCode: 'Checked',
    certiCode: 'Authentication number',
    certiCodeExpired: 'Timed out. Please send the authentication number again.',
    inputCertiCode: 'Enter authentication number',
    inputCurrentSecPassword: 'Enter current security password (4 digits)',
    inputNewSecPassword: 'Enter new security password (4 digits)',
    inputCurrentPassword: 'Enter current password',
    inputNewPassword: 'Enter new password',
    kycOTPerror:'Please register for OTP first.',
    certiCompleted:'Checked',
    auditing:'Under examination',
    enterNicknameValidation: 'Please enter your name in English',
    setSucceed: 'The change has been completed. Please log in again.',
    setFailed: 'The change has failed. Please contact the administrator.',
    kycInfo:'Please write "Tiger Wallet" on a white paper and date today, and hold it with your ID and take a picture so that your face shows up',
    alreadyOtp: 'You have already received an OTP.',
    myinfoOtpComplete: 'OTP setup is complete.',
    myinfoOtpError: 'nvalid authentication code or OTP code.',
    changeOtpComplete: 'completed authentication of phone. Please set OTP again.',
    captureError:'Filming failed. Please try again.',
    fileUploadComplete: 'File registered successfully.',
    fileUploadError: 'File registration failed. Please contact the administrator.',

};

export default string;
