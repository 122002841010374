import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React from 'react';
import "../css/signUp.scss";

import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { stringReducer, setLogin, setLogout, setCoinBalance, setCoinList, setCoinCurrency } from "../actions";
import { Link, Route, withRouter } from "react-router-dom";
import { formatBalance } from "../util/coinUtil";
import string from '../language/StringKo';
import Header from '../components/Header';
import con from '../const';
import Top from './Top';

const FormItem = Form.Item;

class MyWallet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blockchains: [],
            balances: [],
            // myWalletDialogOpen: false,
            // selectedRow: null,
            exchangeRate: global.exchangeRate || [],
            currency: global.currency,
            exchangeRateLoaded: false,
        };
    }
    componentDidMount() {
        this.requestSystemListBlockchain();
        this.state.exchangeRate.length === 0 && this.requestCurrencyExchangeRate().then(() => {
            this.setState({ exchangeRate: global.exchangeRate })
        })
        // this.requestBalance();
    }
    requestBalance = async () => {
        console.log("##### Request balance")
        httpGet(httpUrl.balance, [0], {}).then((result) => {
            result = result.data;
            console.log(result)
            this.props.setCoinBalance(result);
            // this.setState({ balances: result })
        }).catch(e => {
            console.log("## requestBalance error: " + e)
        });
    }

    requestSystemListBlockchain = () => {
        if (this.props.coinList.length > 0) {
            this.requestBalance();
        } else {
            console.log("## Loading systemListBlockchain");
            httpGet(httpUrl.systemListBlockchain, [this.props.language], {}).then(async (result) => {
                result = result.data;
                console.log('## systemListBlockchain loaded');
                result.forEach(obj => {
                    obj.status = true;
                    obj.address = '';
                    obj.balance = 0;
                    obj.balanceLoaded = false;
                    obj.price = 0;
                    obj.priceLoaded = false;
                    obj.value = 0;
                    obj.valueLoaded = false;
                    obj.purchaseFee = 0;
                    obj.sendInFee = 0;
                    obj.sendExFee = 0;
                    obj.tradeFee = 0;
                    obj.feeLoaded = false;
                    obj.serverWallet = '';
                    obj.serverWalletLoaded = false;
                    obj.change = 0.0;
                    obj.market = 0.0;
                    obj.trends = [];
                })
                await this.props.setCoinList(result);
                await this.requestCurrency();
                // this.setState({ blockchains: result })
            }).catch((e) => {
                console.log('## systemListBlockchain load error: ' + e)
            });
        }

    }

    requestCurrency = (hide) => {
        console.log("## Request currency");
        return new Promise((resolve, reject) => {
            httpGet(httpUrl.currencies, [], {}, hide).then(async (result) => {
                this.props.setCoinCurrency(result.data);
                this.state.coinCurrencyUpdateTime = new Date().toLocaleTimeString();
                this.state.currencyLoaded = true;
                resolve();
                await this.requestBalance();
            }).catch(e => {
                console.log("## requestCurrency error: " + e);
                reject(e);
            });
        })
    }

    requestCurrencyExchangeRate = (hide) => {
        if (con.enableForeignExchangeRate) {
            console.log("##### Request currencyExchangeRate")
            return new Promise((resolve, reject) => {
                httpGet(httpUrl.currencyExchangeRate, [], {}, hide).then((result) => {
                    console.log("## Request currencyExchangeRate loaded")
                    result = result.data;
                    global.exchangeRate = [
                        1,
                        result.find(x => x.currency == 'KRWUSD').rate,
                        result.find(x => x.currency == 'KRWCNY').rate,
                        result.find(x => x.currency == 'KRWJPY').rate,
                    ]
                    this.state.exchangeRateLoaded = true;
                    resolve();
                }).catch(e => {
                    console.log("## requestCurrencyExchangeRate error: " + e)
                    reject();
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                this.state.exchangeRateLoaded = true;
                global.exchangeRate = [1, 1, 1, 1];
                resolve();
            })
        }
    }

    calTotalValue() {
        let totalValue = 0;
        let allLoaded = true;
        this.props.coinList.forEach(coin => {
            allLoaded &= coin.valueLoaded;
            totalValue += coin.value;
        });
        // if (allLoaded) return totalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // else return '-';
        // return (totalValue * this.state.exchangeRate[this.state.currency]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (totalValue * this.state.exchangeRate[this.state.currency]).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    logout = () => {
        const { language, string, setLogout, history } = this.props;
        Modal.confirm({
            title: string.myinfoLogout,
            content: string.myinfoLogoutConfirm,
            okText: string.ok,
            cancelText: string.cancel,
            onOk() {
                setLogout();
                history.push('/');
            },
            onCancel() {
            },
        });
    }
    render() {
        const { language, string } = this.props;
        const { close } = this.state;

        return (
            <div className="wrapper">
                <Top />
                <div className="container">
                    <Header />
                    <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                        <div className="wallet-bg">
                            <div className="wallet-title"></div>
                            <div className="wallet-titbox">
                                <div className="wallet-logo"></div>
                                {this.calTotalValue()} {string.localCoinUnit[this.state.currency]}

                            </div>
                        </div>

                        {this.props.coinList.map((value, index) => {
                            let balance = parseFloat(Math.round(value.balance * 10000) / 10000).toFixed(4);
                            return (


                                < div className="blockchain-wrapper" key={index}>
                                    <Link to={{
                                        pathname: "/myWalletDialog",
                                        state: {
                                            coinType: value.coinType
                                        }
                                    }}>
                                        <div className="blockchain-content">
                                            <div className="blockchain-image"><img src={value.image} /></div>
                                            <div className="blockchain-content-name">{value.name}</div>
                                            <div className="blockchain-content2">
                                                <div className="blockchain-content-balance">
                                                    {balance}<span>{value.symbol}</span>
                                                    <div style={{
                                                        textAlign: "right",
                                                        fontSize: 14,
                                                        color: "#000",
                                                        fontFamily: "NotoSansRegular",
                                                        paddingLeft: 10
                                                    }}>{(value.value * this.state.exchangeRate[this.state.currency]).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {string.localCoinUnit[this.state.currency]}</div>
                                                </div>
                                            </div>

                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string,
        isLogin: state.loginReducer.isLogin,
        userinfo: state.loginReducer.userinfo,
        coinList: state.coinListReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: lan => {
            dispatch(stringReducer(lan));
        },
        setLogin: userinfo => {
            dispatch(setLogin(userinfo));
        },
        setLogout: lan => {
            dispatch(setLogout());
        },
        setCoinList: coinList => {
            dispatch(setCoinList(coinList));
        },
        setCoinBalance: coinList => {
            dispatch(setCoinBalance(coinList));
        },
        setCoinCurrency: coinList => {
            dispatch(setCoinCurrency(coinList));
        },
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyWallet));