export default {
  serverProtocol: 'https',
  serverIp: 'back.bit-tiger.kr',

  // serverProtocol: 'http',
  // serverIp: "localhost",

  serverPort: '18001',
  serverContext: '',

  // serverIp: '192.168.0.25',
  // serverPort: '18001',
  // serverContext: '',

  enableForeignExchangeRate: false,

  appCode: 'tigerwallet'
};
