import { Form, Input, Checkbox, Button, Modal, Table, Divider, Image } from 'antd';
import React from 'react';
import "../css/signUp.scss";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { stringReducer, setLogin, setLogout, setCoinCurrency, setCoinList } from "../actions";
import { Link, Route, withRouter } from "react-router-dom";
import { formatBalance } from "../util/coinUtil";
import string from '../language/StringKo';
import moment from "moment";
import { comma } from '../util/numberUtil';
import Header from '../components/Header';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


import Top from './Top';
const FormItem = Form.Item;

class Currency extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            blockchains: [],
            balances: [],
            currency: global.currency,
            exchangeRate: global.exchangeRate,
            coinCurrencyUpdateTime: '',
            graphOpen: 0,

        }

    }


    onClickCoinInfo = (coin) => {
        if (this.state.graphOpen == coin.coinType) this.setState({ graphOpen: 0 });
        else this.setState({ graphOpen: coin.coinType });
    }

    requestCurrency = (hide) => {
        console.log("## Request currency");
        let target
        let check
        return new Promise((resolve, reject) => {
            httpGet(httpUrl.currencies, [], {}, hide).then((result) => {
                // this.setState({ blockchains: this.state.blockchains},() => console.log("dddddddddddddddddddd " + JSON.stringify(this.state.blockchains)))
                this.props.setCoinCurrency(result.data);
                this.setState({
                    coinCurrencyUpdateTime: new Date().toLocaleTimeString(),
                    currencyLoaded: true
                })
                resolve();

            }).catch(e => {
                console.log("## requestCurrency error: " + e);
                reject(e);
            });
        })
    }

    getNoticeList = () => {

        httpGet(httpUrl.noticeList, [1, 3], {})
            .then(res => {
                this.setState({
                    list: res.data.noticeList,
                })
            })
    }
    handleRefresh = () => {
        this.setState({ refreshing: true }, () => {
            this.props.requestCurrency(true).then(() => {
                this.setState({ refreshing: false, coinCurrencyUpdateTime: new Date().toLocaleTimeString() });

            }).catch(e => {
                this.setState({ refreshing: false });
                console.log("## requestCurrency error: " + e)
            });
        });
    }

    componentDidMount(value, item) {
        this.requestSystemListBlockchain();
        this.requestBalance();
        this.getNoticeList();
    }
    requestBalance = () => {
        console.log("##### Request balance")
        httpGet(httpUrl.balance, [0], {}).then((result) => {
            result = result.data;
            console.log(result)
            this.setState({ balances: result })
        }).catch(e => {
            console.log("## requestBalance error: " + e)
        });
    }
    requestSystemListBlockchain = () => {
        console.log("## Loading systemListBlockchain");
        httpGet(httpUrl.systemListBlockchain, [this.props.language], {}).then(async (result) => {
            result = result.data;
            result.forEach(obj => {
                obj.status = true;
                obj.address = '';
                obj.balance = 0;
                obj.balanceLoaded = false;
                obj.price = 0;
                obj.priceLoaded = false;
                obj.value = 0;
                obj.valueLoaded = false;
                obj.purchaseFee = 0;
                obj.sendInFee = 0;
                obj.sendExFee = 0;
                obj.tradeFee = 0;
                obj.feeLoaded = false;
                obj.serverWallet = '';
                obj.serverWalletLoaded = false;
                obj.change = 0.0;
                obj.market = 0.0;
                obj.trends = [];
            })
            console.log('## systemListBlockchain loaded');
            await this.props.setCoinList(result);
            await this.requestCurrency();

        }).catch((e) => {
            console.log('## systemListBlockchain load error: ' + e)
        });
    }
    logout = () => {
        const { language, string, setLogout, history } = this.props;
        Modal.confirm({
            title: string.myinfoLogout,
            content: string.myinfoLogoutConfirm,
            okText: string.ok,
            cancelText: string.cancel,
            onOk() {
                setLogout();
                history.push('/');
            },
            onCancel() {
            },
        });
    }

    formatXAxis = (item) => {
        if (item === "logDate") return `${item.slice(0, 2)}`
        else return item
    };
    formatYAxis = (item) => item.toLocaleString();
    formatTooltip = (item) => item.toLocaleString();

    render() {


        const columns = [

            {
                title: "",
                dataIndex: "idx",
                className: "column-center",
                render: (data) => <div style={{ color: 'gray', fontWeight: 500, textAlign: 'center' }}>{data}</div>

            },
            {
                title: "",
                dataIndex: "title",
                className: "column-center",
                render: (data) => <div className="notice-title2"> {data}</div >

            },
            {
                title: "",
                dataIndex: "createDate",
                className: "column-center noticeDate",
                render: (data) => <div style={{ color: 'gray', fontWeight: 500 }}>{moment(data).format("MM-DD")}</div>
            },

        ];
        console.log(this.props.userinfo)
        const { language, string } = this.props;
        return (
            <div className="wrapper">
                <Top />

                <div className="container">
                    <Header />
                    <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                        <div className="main-box">
                            <div className="main-title">
                                <div className="main-text">{string.notice}</div>
                                <div className="more-btn">
                                    <Link to={"/notice"}>
                                        <Button>더보기
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                            <Divider />
                            <Table
                                className="noticeTable"
                                rowKey={(record) => record.idx}
                                columns={columns}
                                dataSource={this.state.list}
                                pagination={{ hideOnSinglePage: true }}
                            />
                        </div>
                        <div className="main-box">
                            <div className="main-title cur-title">
                                <div className="main-text">{string.currency} </div>
                                <div className="sub-text">

                                    {this.state.coinCurrencyUpdateTime} {string.updated} {string.criteriaCoinmarketcap}
                                </div>
                            </div>
                            <Divider />
                            {this.props.coinList.map((value, index, item, target) => {

                                let changeColor = '#000000';
                                let changeBackColor = '#ffffff';
                                let arrowImage = "";

                                if (value.change < 0) {
                                    changeColor = '#f11015';
                                    changeBackColor = '#ffffff';
                                    arrowImage = require('../img/common/arrow_down.png');
                                }
                                if (value.change > 0) {
                                    changeColor = '#0ab512';
                                    changeBackColor = '#ffffff';
                                    arrowImage = require('../img/common/arrow_up.png');
                                }

                                let balanceRow = this.state.balances.find(x => x.coinType == value.coinType);
                                let balance = 0;
                                if (balanceRow != null) balance = balanceRow.balance;
                                return (
                                    <div className="blockchain-wrapper" key={index} onClick={() => { this.onClickCoinInfo(value) }}>
                                        <div className="blockchain-content" onClick={() => { this.onClickCoinInfo(value) }}>
                                            <div className="blockchain-image"><img src={value.image} onClick={() => { this.onClickCoinInfo(value) }} /></div>
                                            <div className="blockchain-content-name">{value.name}</div>
                                            <div className="blockchain-content2">
                                                <div className="blockchain-content-balance">{comma(value.price)}
                                                    {/* <span>{value.symbol}</span> */}<span>{string.krw}</span>
                                                    {/* <span style={{ float: "right",fontSize:15 }}><Image src={arrowImage} width={10} />{value.change}%</span> */}
                                                </div>
                                                <div className="blockchain-content-balance2"><Image src={arrowImage} width={10} />{value.change}%</div>
                                            </div>
                                        </div>
                                        {this.state.graphOpen == value.coinType && value.trendY != null && (

                                            <div className="blockchain-graph">
                                                <ResponsiveContainer width="100%" aspect={3 / 1}>
                                                    <LineChart width={400} height={200} data={value.trends.reverse()}>
                                                        <CartesianGrid strokeDasharray="8 8 8" />
                                                        <XAxis
                                                            dataKey={"logDate"}
                                                            tickFormatter={this.formatXAxis}
                                                        />
                                                        <YAxis
                                                            width={70}
                                                            tickFormatter={this.formatYAxis}
                                                            domain={['auto', 'auto']} />
                                                        <Tooltip
                                                        />
                                                        <Line
                                                            type="monotone"
                                                            dataKey={"price"}
                                                            stroke="#8884d8"
                                                            activeDot={{ r: 5 }}
                                                            dot={false}
                                                            isAnimationActive={true}
                                                        />
                                                    </LineChart>
                                                </ResponsiveContainer>
                                            </div>
                                        )}
                                    </div>

                                );

                            })}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string,
        isLogin: state.loginReducer.isLogin,
        userinfo: state.loginReducer.userinfo,
        coinList: state.coinListReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: lan => {
            dispatch(stringReducer(lan));
        },
        setLogin: userinfo => {
            dispatch(setLogin(userinfo));
        },
        setLogout: lan => {
            dispatch(setLogout());
        },
        setCoinCurrency: coinList => {
            dispatch(setCoinCurrency(coinList));
        },
        setCoinList: coinList => {
            dispatch(setCoinList(coinList));
        }
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Currency));