import React, { Component } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import SignUp from '../pages/SignUp';
import SignUpOk from '../pages/SignUpOk';
import Privacy from '../pages/Privacy';
import Login from '../pages/Login';
import MyWallet from '../pages/MyWallet';
import Currency from '../pages/Currency';
import Exchange from '../pages/Exchange';
import Mining from '../pages/Mining';
import Notice from '../pages/Notice';
import MiningDetail from '../pages/MiningDetail';
import Mypage from '../pages/Mypage';

import MyWalletDialog from '../components/dialog/MyWalletDialog';
import WalletReceive from '../components/dialog/WalletReceive';
import WalletSend from '../components/dialog/WalletSend';

import ModifyPassword from '../pages/ModifyPassword';

export default class Content extends Component {

    render() {
        return (
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/myWallet" component={MyWallet} />
                <Route exact path="/currency" component={Currency} />
                <Route exact path="/notice" component={Notice} />
                <Route exact path="/exchange" component={Exchange} />
                <Route exact path="/mining" component={Mining} />
                <Route exact path="/miningDetail" component={MiningDetail} />
                <Route exact path="/mypage" component={Mypage} />

                <Route exact path="/myWalletDialog" component={MyWalletDialog} />
                <Route exact path="/walletReceive" component={WalletReceive} />
                <Route exact path="/walletSend" component={WalletSend} />


                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/signup" component={SignUp} />
                <Route exact path="/signupok" component={SignUpOk} />
                <Route path="/password/modify" component={ModifyPassword} />
                <Redirect to="/" />
            </Switch>
        );
    }
}
