import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React from 'react';
import "../css/signUp.scss";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto from 'crypto';
import { connect } from "react-redux";
import { stringReducer, setLogin, setLogout } from "../actions";
import { Route, withRouter } from "react-router-dom";

const FormItem = Form.Item;

class Login extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleSubmit = (values) => {
        const { language, string } = this.props;
        console.log('handleSubmit');
        const form = this.formRef.current;

        let password = form.getFieldValue("password") + form.getFieldValue("id").trim();

        let passwordHash = crypto
            .createHash('sha256')
            .update(password, 'utf8')
            .digest()
            .toString('hex');

        values.password = passwordHash;
        values.id = values.id.trim();
        console.log(values);

        httpPost(httpUrl.login, [], values).then((result) => {
            console.log("## login result: " + JSON.stringify(result, null, 4));
            result = result.data;

            if (result.result) {
                console.log("## login success");

                this.props.setLogin(result.user);
                this.props.history.push('/Currency');

            } else {
                console.log("## login fail");

                if (result.reason == 'EMAIL_CONFIRM_NOT_YET') {

                } else {
                    let message = string.loginFailedMessage;

                    if (result.reason == 'INVALID_LOGIN_ID') message = string.loginFailedWrongId;
                    else if (result.reason == 'INVALID_LOGIN_PASSWORD') message = string.loginFailedWrongPassword;
                    else if (result.reason == 'INVALID_LOGIN_INFO') message = string.loginFailedInvalidLoginInfo;
                    else if (result.reason == 'EXPIRED') message = string.loginFailedExpiredId;
                    Modal.info({
                        title: string.loginFailedTitle,
                        content: (
                            <div>
                                {message}
                            </div>
                        ),
                        onOk() { },
                    });
                }
            }
            // if (dlg != null) dlg.dismiss();

        }).catch(e => {
            // if (dlg != null) dlg.dismiss();

            console.log('## signUp error: ' + e);
            Modal.info({
                title: string.login,
                content: (
                    <div>
                        {string.loginFailedMessage}
                    </div>
                ),
                onOk() { },
            });
            // if (callback instanceof Function) callback(false, '');
        });
    }
    render() {
        const { language, string } = this.props;
        return (
            <div className="wrapper">
                <div className="BT-HD-right-container">
                    <div className={`BT-HD-right-element ${language == 'ko' ? 'color-blue' : ''}`} onClick={() => this.props.setLanguage('ko')}>
                        KO
                    </div>
                    <div className={`BT-HD-right-element ${language == 'en' ? 'color-blue' : ''}`} onClick={() => this.props.setLanguage('en')}>
                        EN
                    </div>
                    <div className={`BT-HD-right-element ${language == 'ja' ? 'color-blue' : ''}`} onClick={() => this.props.setLanguage('ja')}>
                        JA
                    </div>
                </div>
                <div className="header">
                    {string.welcome}
                </div>
                <div className="container-box" style={{ textAlign: 'center' }}>
                    <Form onFinish={this.handleSubmit} className="form" ref={this.formRef}>
                        <div>
                            <div className="login-field-icon">
                                <img src={require('../img/login/id.png')} />
                            </div>
                            <div className="login-field-input">
                                <Form.Item
                                    name="id"
                                    rules={[
                                        {
                                            required: true,
                                            message: string.signUpRuleMsg09
                                        },
                                    ]}
                                >
                                    <Input className="input22"
                                        placeholder={string.idEmail}
                                    />
                                </Form.Item>
                            </div>

                        </div>
                        <div>

                            <div className="login-field-icon">
                                <img src={require('../img/login/password.png')} />
                            </div>
                            <div className="login-field-input">
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: string.signUpRuleMsg10
                                        },
                                    ]}
                                >
                                    <Input type="password" className="input2"
                                        placeholder={string.password}
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <div>

                            <div className="signupbtn">
                                <Form.Item>
                                    <Button className="signup-btn signup-btn-ok" type="primary" htmlType="submit">{string.login}</Button>
                                </Form.Item>
                            </div>
                            <div className="signup-link" onClick={() => this.props.history.push('/signup')}>
                                {string.signUp}
                            </div>
                        </div>

                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string,
        isLogin: state.loginReducer.isLogin,
        userinfo: state.loginReducer.userinfo,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: lan => {
            dispatch(stringReducer(lan));
        },
        setLogin: userinfo => {
            dispatch(setLogin(userinfo));
        },
        setLogout: lan => {
            dispatch(setLogout());
        },
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));