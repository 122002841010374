const string = {
  completed: '完了',
  ok: '確認',
  cancel: '取消',

  welcome: 'タイガーウォレットへようこそ、歓迎します!!',
  localCoinUnit: ['ウォン', 'USD', 'CNY', 'JPY'],

  /*********signUp**********/
  signUp: '会員登録',
  signUpMsg01: '会員登録が完了しました。',
  signUpMsg02: '加入されたID(eメール)宛に認証メールが送信されました。',
  signUpMsg03: 'メール認証を完了してからログインできます。',
  signUpRuleMsg01: '既に加入されているメールアドレスです。',
  signUpRuleMsg02: 'eメール形式が正しくありません。',
  signUpRuleMsg03: 'パスワード複雑度を満たしていません',
  signUpRuleMsg04: '加入処理中に不明なエラーが発生しました。',
  signUpRuleMsg05: '入力された2つのパスワードが異なります。',
  signUpRuleMsg06: '約款に同意してください。',
  signUpRuleMsg07: '英文、数字、特殊文字で8~15文字を入力してください。',
  signUpRuleMsg08: '携帯電話番号を正確に入力してください。',
  signUpRuleMsg09: 'eメールを入力してください。',
  signUpRuleMsg10: 'パスワードを入力してください。',
  signUpRuleMsg11: 'パスワード確認を入力してください。',
  signUpRuleMsg12: '名前を入力してください。',
  signUpRuleMsg13: '電話番号を入力してください。',
  signUpRuleMsg14: 'セキュリティパスワード数字4桁を入力してください。',
  signUpRuleMsg15: 'セキュリティパスワードを同じように入力してください。',

  signUpFailTitle: '会員加入失敗',
  signUpFail: '会員加入に失敗しました。',
  signUpCancleMsg: '会員加入を取り消して、メインページに移動しますか。',
  signUpAgreement: '個人情報収集及び利用',
  signUpOtherLoginMsg: '<span style="color:#00aab6; font-weight:bold;">ネイバー</span>または<span style="color:#00aab6; font-weight:bold;">カカオ</span>ログインを使用すると、< span style="color:#00aab6; font-weight:bold;">別途会員加入せずにご利用</span>できます。',
  emailRule03: 'eメール形式を入力してください。',
  cancle: '取消',
  idEmail: 'ID(eメール)',
  passwordRule: 'パスワード(英字、数字、特殊文字組み合わせ8~15桁)',
  passwordConfirm: 'パスワード確認',
  name: '名前',
  inputTel: '電話番号入力',
  inputSecurity: 'セキュリティパスワード数字4桁',
  inputSecurityConfirm: 'セキュリティパスワード確認',

  dupCheck: '重複確認',
  emailDuplCheckError: 'eメール重複確認に失敗しました。',
  emailDuplCheckErrorEmailDupl: '既に登録されているeメールです。',
  emailDuplCheckErrorInvalidEmail: 'eメール形式が正しくありません。',
  needEmailDuplCheck: 'eメール重複確認が必要です。',

  sendCertiCode: '認証リクエスト',
  certiCodeFail: '認証番号を入力してください。',
  certiCodeWrong: '認証番号が正しくありません。',
  certiCodeSendFail: '認証番号送信に失敗しました。',
  certiCodeNotVerified: '電話番号認証が必要です。',

  signUpErrorTitle: 'ウォレット生成失敗',
  signUpErrorIdDuplication: '既に登録されているeメールです。 eメールをご確認ください。',
  signUpErrorInvalidEmailType: 'eメール形式が正しくありません。 もう一度ご確認ください。',
  signUpErrorMaxPhone: '電話番号当たりの登録可能アカウント数を超過しました。',
  signUpErrorMessage: 'ウォレット生成に失敗しました。 しばらくしてからもう一度お試しください。',

  emailResent: '認証メールが再送されました。',
  emailResendFailed: '認証メール再送に失敗しました。',
  invalidEmail: '正しくないeメールです',
  emailAlreadyVerified: '既に認証されているeメールです。',
  inputJoinEmail: '加入しているeメールを入力してください。',

  emailResendGuide1: '認証メールが届いていなかったり、認証時間が満了されましたか。',
  emailResendGuide2: '次の認証メール再送信ボタンをクリックして、再び認証を依頼してください。',
  emailResend: '認証メール再送信',

  /*********Trade Log**********/
  noCoins: '取引がありません。',
  tradeLog: '取引履歴',
  walletSend: '送る',
  walletReceive: '受け取る',
  selectCoin: 'コインを選んでください。',
  copyComplete: 'コピーしました。',
  address: 'ウォレットアドレス',
  priceInsert: '金額入力',
  copyAddress: 'アドレスコピー',
  qrContainPrice: 'QRに含まれる金額',
  depositAddress: '入金アドレス',
  insertAddress: "入金アドレス入力",
  insertAmount: "数量入力",
  insertMemo: "メモ(選択事項)",
  securityPw: "セキュリティパスワード",
  pleaseInsertAddress: "入金アドレスを入力してください。",
  pleaseInsertAmount: "数量を入力してください。",
  pleaseInsertSecurityPw: "セキュリティパスワードを入力してください。",
  pleaseInsertOtp: "otpを入力してください。",

  /*********約款等**********/
  policyView: '約款を見る',
  needAgreement: '約款に同意してください。',
  policyTitle: '個人情報収集及び利用約款',
  policyTitle2: ' (必須)個人情報収集及び利用約款',

  policyContent: [
    { title: '第1章総則', content: '' },
    {
      title: '第1条(目的)',
      content:
        '本約款は株式会社ハッシュワンカンパニー(以下、「会社」という)が提供するアプリ及びウォレットで提供するサービス(以下、「サービス」という)の利用条件及び手続きに関する会社と利用者間の権利義務並びに責任事項、その他必要事項を規定することを目的とします。\n',
    },
    {

      title: '第2条(約款の明示、説明と改訂)',
      content: [
        '① この約款の内容は、会社のサービス等その他の方法で利用者に公知し、利用者がこの約款に同意することで効力が発生します。\n',
        '② 会社は、『約款の規制に関する法律』、『情報通信網の利用促進及び情報保護等に関する法律』等の関連法律を違反しない範囲内で本約款を改正することができます。\n',
        '③ 会社が約款を改訂する場合は適用日付及び改訂事由を明示して現行約款と一緒に会社サイトの初期画面やポップアップ画面、または公知事項により、その適用日の7日前から公知します。 但し、利用者に不利だったり重大な内容の変更がある場合は、適用日30日前に利用者に通知します。\n',
        '④ 会社が前項により改訂約款を公示する際、利用者に対して7日間の期間内に意思表示をしなければ意思表示が表明されたものとみなすという旨を明確に告知したにもかかわらず、利用者が明示的に拒否意思を表示しなかった場合、利用者が改訂約款に同意したものとみなします。\n',
        '⑤ 利用者が改定約款の適用に同意しないという明示的意思を表明した場合、会社は改定約款の内容を適用することができず、その場合、利用者は利用契約を解約することができます。 但し、既存約款が適用できない特別な事情がある場合は、会社は利用契約を解約することができます。\n',
      ]
    },
    {
      title: '第3条(約款外準則) ',
      content:
        '会社は、サービスについて、本約款以外に別途公知事項、個別約款、個別同意事項、利用案内、運用制作、詳細指針等(以下、総じて『詳細指針』といいます)を定め、それを利用者に知らせたり同意を得ることができるし、詳細指針のうち、利用者の同意を得た内容がこの約款と相反する場合は、『詳細指針』が優先的に適用されます。 本約款に定めてない事項や解釈については、『詳細指針』及び関連法令、商慣習に従います。\n',
    },
    {

      title: '第4条(用語の定義)',
      content: [
        '① この約款で使う用語の定義は、次のとおりです。\n',
        '1. 暗号通貨:ビットコイン(BTC)、イーサリアム(ETH)等のブロックチェーンで電子的に存在する通貨のことをいいます。\n',
        '2. 利用者:この約款を承認して会社が提供するウォレットを登録した利用者のことをいいます。\n',
        '3. アプリ:暗号通貨を保存したり、送受信できるスマートフォンオンライン専用プログラムをいいます。\n',
        '4. ウォレット:スマートフォンに暗号通貨を保存できるアプリのことをいいます。\n',
        '5. パスワード:利用者とウォレット間の同一性確認と暗号通貨セキュリティのために利用者が設定した文字の組み合わせのことをいいます。\n',
        '6. 復旧単語:ウォレットを紛失した場合、復旧できる12の暗号化された単語をいいます。\n',
      ]
    },
    {
      title: '第2章サービス利用申請及び承諾(利用者加入及び解約) ',
      content: '',
    },
    {

      title: '第5条(利用契約の成立)',
      content: [
        '① 利用者はウォレットを生成する時に本約款に同意するという意思を会社が提供するアプリに表示をすることでウォレット使用を申請します。\n',
        '② 利用契約はウォレットを登録した単位で締結します。 利用契約が成立すると、利用申請者はウォレットの利用者として登録されます。\n',
      ]
    },
    {
      title: '第6条(利用申請)',
      content: '利用申請は、会社が提供するアプリにウォレットを登録する時、自動的に利用申請が承認されます。\n',
    },
    {
      title: '第7条(利用者情報の使用についての同意及び利用申請の承諾)',
      content: [
        '① 利用者情報の使用についての同意\n',
        '1. 会社は本利用契約の履行と本利用契約上のサービスを提供する目的で利用者の個人情報を利用します。\n',
        '2. 利用者が会社及び会社と提携したサービスを便利に利用できるように、利用者情報は会社と提携した業者に提供することがあります。 但し、会社は利用者情報の提供前に、提携業者、提供目的、提供する利用者情報の内容等を事前に公知して利用者の同意を得なければなりません。\n',
        '3. 利用者は利用者情報の修正により、いつでも個人情報の閲覧や修正ができます。\n',
        '4. 利用者が利用申請書に利用者情報を記載して会社に本約款に基づいて利用申請を行うことは、会社が本約款に基づいて利用申請書に記載された利用者情報を収集、利用及び提供することに同意するものとみなされます。\n',
      ]
    },
    {

      title: '第8条(利用契約の中止及び解約)',
      content: [
        '① 利用契約及びアプリ使用の解約は、利用者がスマートフォンでアプリを削除することで解約できます。\n',
        '② 会社は、加入申請者の申請について、次の各号に該当する事由がある場合は承諾をしないことができ、加入後も、次の各号の事由が確認できる場合は承諾を取り消したり利用契約が解約できます。\n',
        '1. 本約款により以前会員資格を喪失したことがある場合\n',
        '2. 虚偽または誤った情報を記載または提供したり会社が提示する内容を記載しない場合\n',
        '3. 加入申請者の責に帰すべき事由により承認できなかったり、その他本約款で規定した諸事項を違反して申請した場合\n',
        '4. 不正な用途または別個営業を目的としてサービスを利用しようとする場合\n',
        '5. 関連法令に違反したり、社会の安寧秩序もしくは公序良俗を阻害し得る目的で申請した場合\n',
        '6. 登録申請者の年齢が満19歳未満の場合\n',
        '③ 会社は会員が提供した情報が事実と一致するかの可否を確認するために法令により許可された範囲内で専門機関を通じた実名確認または本人認証を依頼することができます。\n',
      ]
    },
    { title: '第3章 会社の義務', content: '' },
    {

      title: '第9条利用者個人情報とサービスに対する会社の義務',
      content: [
        '① 会社は関連法令と本約款を遵守し、サービスを継続的で安定的に提供するために最善を尽くして努力します。\n',
        '② 会社は、利用者が安全にサービスが利用できるように個人情報保護のためにセキュリティシステムを備えることができ、個人情報処理方針を公示して順守します。\n',
        '③会社は業務に関連して利用者の事前同意なしに利用者全体または一部個人情報等を、特定利用者の情報が区別できない統計資料に作成して使用することができて、そのため利用者はコンピューターにクッキーを転送することができます。 但し、その場合、利用者はクッキー送信を拒んだりクッキー送信について警告するように使用する機器の設定変更ができるが、その場合 会社の帰責事由なくクッキー設定変更によってサービス利用が変更されることがあります。\n',
        '④ 会社は、サービス利用に関連して利用者から提起された意見や不満が正当だと認めた場合はそれを処理しなければならないし、サービス内の掲示板、 E-mail 等で処理過程や結果を利用者に伝えることができます。\n',
        '⑤ 会社は情報通信網法、位置情報保護法、通信秘密保護法、電気通信事業法等のサービスの運用·維持に関連ある法規を遵守します。\n',
      ]
    },
    { title: '第4章 利用者の義務', content: '' },
    {

      title: '第10条(利用者ウォレットとパスワード管理に対する利用者の義務)',
      content: [
        '① ウォレットとパスワードに関する全ての管理責任は利用者にあります。 利用者が登録したウォレットとパスワードのずさんな管理、不正使用によって発生する全ての結果についての責任は利用者にあり、会社はそれに対する責任を負いません。 但し、会社の故意による場合はこの限りではありません。\n',
        '② 12の復旧単語は必ず紙に記入しておかなければならないし、ウォレットを紛失して復旧する時に12の復旧単語が必要です。 会社はその情報を持っていないため、復旧単語を紛失した場合は復旧できませんが、その時の全ての責任は利用者にあり、会社はそれに対する責任を負いません。 また、本人のミスによって12の復旧単語が流出されて被った損失についての全ての責任も利用者にあり、会社はそれに対する責任を負いません。\n',
      ]
    },
    {

      title: '第11条(情報の提供)',
      content: [
        '1. 会社は固定·無線電話、電子メール、文字サービス(LMS・SMS)、SNS等の方法で以下のようなサービスについて、利用便宜のための利用案内及び商品に関する情報を提供することができます。 その場合、利用者はいつでも受信を拒否することができます。\n',
        '① イベント及び行事関連等のサービス\n',
        '② その他会社が随時決定して利用者に提供するサービス\n',
        '2. 利用者はサービスを通じていつでも本人の個人情報を閲覧·修正·修正を要請することができます。 利用者は、会員登録申請時に記載した事項が変更された場合、サービスで直接修正するか、カスタマーセンターを通じて会社に変更事項を通知しなければなりません。 利用者は前項の変更を会社に通知しなかったため発生した不利益について、会社は責任を負いません。\n',
      ]
    },
    { title: '第5章 サービス利用総則', content: '' },
    {

      title: '第12条(サービスの種類)',
      content: [
        '① 会社が提供するサービスには暗号通貨を保存するウォレット機能、他人に暗号通貨が送受信できる機能等があります。 但し、利用者が提供する暗号通貨ウォレットアドレスの正確性についての責任は利用者にあり、会社は利用者が誤って記載した暗号通貨ウォレットアドレスに暗号通貨を送ることについて責任を負いません。\n',
        '② 会社が提供するサービスの種類は、会社の事情により随時変更されることがあり、提供されるサービスの著作権及び知的財産権は、会社に帰属します。\n',
        '③会社はサービスと関連して 会社が定めた利用条件に基づいてアカウント、ウォレット、サービス等を利用できる利用権限だけを利用者に与え、利用者はそれを活用した類似サービスの提供及び商業的活動をすることはできません。\n',
      ]
    },
    {

      title: '第13条(サービス内容のお知らせ及び変更)',
      content: [
        '① 会社は、サービスの種類によって各サービスの特性、手続及び方法に関する事項をウェブサイトを通じて公知し、利用者は、会社が知らせた各サービスに関する事項を理解した上で、サービスを利用しなければなりません。\n',
        '② 会社は相当の理由がある場合、運用上、技術上の必要に応じて提供している個別サービスの全部または一部が変更できます。 この場合、会社は最低7日前にその内容を利用者に知らせます。\n',
      ]
    },
    {

      title: '第14条(サービスの維持及び中止)',
      content: [
        '① サービスの利用は、会社の業務上または技術上の特別な支障がない限り、1日24時間を原則とします。 但し、定期点検等の必要で、会社が事前または事後に告知して定めた日または時間は、この限りではありません。\n',
        '② 会社はサービスを一定の範囲に分割し、別途各範囲毎の利用可能時間を定めることができます。 その場合、その内容を事前または事後に知らせます。\n',
        '③ 会社は次の各号に該当する場合、サービス提供を中止することができます。 その場合、会社はやむを得ない場合でない限り、サービス提供中止を事前に知らせます。\n',
        '1. サービス用設備補修等の工事によりやむを得ない場合\n',
        '2. 電気通信事業法に規定された基幹通信事業者が電気通信サービスを中止した場合\n',
        '3. 直接会社が提供するサービスでなく、提携業者等の第三者を利用して提供するサービスの場合、提携業者等の第三者がサービスを中止した場合\n',
        '4. その他の不可抗力的事由がある場合\n',
        '④ 会社は国家非常事態、停電、サービス設備の障害またはサービス利用の輻輳等で正常サービス利用に支障がある時は、サービスの全部または一部を制限したり停止することができます。\n',
      ]
    },
    {
      title: '第15条 利用制限',
      content: [
        '① 会社は、次の各号に該当する場合、会員のサービス利用及び接続を制限することができます。\n',
        '1. パスワード及びセキュリティパスワードが連続してエラーになった場合\n',
        '2. ハッキング及び事故が発生した場合\n',
        '3. 名義盗用と疑われる場合\n',
        '4. 関連法令に基づいて国家機関からのサービス制限依頼がある場合\n',
        '5. マネーロンダリング、不公正取引、犯罪行為等に関わっていたり、関わっていると合理的に疑われる場合\n',
        '6. 会員登録された利用者が満19歳未満と確認された場合\n',
        '7. その他各号に準じる事由が発生したりそのような事由発生の防止のために措置が必要な場合\n',
        '② 会社は、次の各号に該当する場合、会員の入金及び出金利用を制限することができます。\n',
        '1. 会員として加入された利用者が満19歳未満と確認された場合\n',
        '2. ハッキングや詐欺事故が発生したり発生したものと疑われる場合\n',
        '3. 名義盗用と疑われる場合\n',
        '4. 関連法令に基づいて国家機関からのサービス制限依頼がある場合 \n',
        '5. マネーロンダリング、不公正取引、犯罪行為等に関わっていたり、関わっていると合理的に疑われる場合\n',
        '6. 会員本人からの依頼がある場合\n',
        '7. 暗号通貨が送金されたが、会員アカウントに反映されない場合、または実際入出金依頼と異なった入出金された場合\n',
        '8. その他各号に準じる事由に該当する事由が発生したり、そのような事由の発生を防ぐための措置が必要な場合\n',
        '③ 本条に基づいてサービス利用を制限したり利用契約を解約する場合、会社はＥメール、SMS、App Push等で会員に通知します。\n',
        '④ 会員は、本条による利用制限等について会社が定めた手続に添って異議申立てができます。 その時、異議が正当であると会社が認めた場合、会社は直ちにサービス利用を再開します。\n',
      ]
    },
    {

      title: '第16条(利用契約の解除)',
      content: [
        '① 利用者はカスタマーセンターを通じていつでも利用契約の解約が申請できて、会社は関連法令が定めるところに基づいて直ちに処理しなければなりません。\n',
        '② 会社は次のような事由が発生した場合、会員に時間を決めてサービス利用を制限と同時に是正を要求することができます。 是正要求にもかかわらず、相当な期間内に是正できなかったり、繰り返して同じような違反行為を行った場合は、利用契約を解約することができます。\n',
        '1. 本約款第10条に規定された利用者の義務を違反したり第15条に定められた利用制限事由に該当する場合\n',
        '2. 著作権法を違反した不法プログラムの提供及び運用妨害、情報通信網利用促進及び情報保護等に関する法律を違反した不法通信及びハッキング、悪性プログラムの配布、アクセス権限超過行為等のように関連法令を違反した場合\n',
        '3. 会社が提供するサービスの円滑な進行を妨害する行為をしたり試みた場合\n',
        '4. その他各号に準じる準じる事由で本契約が維持できない事由が発生する場合\n',
        '③ 前項により利用契約を解約する時、サービス利用で獲得した全ての特典が消滅し、会社はそれに対して別途補償しません。\n',
        '④ 本条によりサービス利用契約を解約する場合、会社は利用者にeメール、SMS、App Push等で通知します。\n',
        '⑤ 利用契約の解約が完了する場合、関連法令及び個人情報処理方針に則って、会社が保有すべき情報を除いた利用者の全ての情報が削除されます。\n',
        '⑥ 第2項により、会社が利用契約を解約する場合、会社は利用者の異議申立受付及び処理等のために一定期間の間利用者の情報が保管でき、当該期間の経過後に利用者の情報(但し、入出金内訳は除く)を削除します。\n',
      ]
    },
    {

      title: '第17条（情報の提供及び広告の掲載）',
      content: [
        '① 会社は、サービス運用につき、サービスに関する各種情報をサービス画面に掲載したり、eメール、SMS、App Push等の方法で利用者に提供することができます。\n',
        '② 会社はサービスを運用につき、会社または提携会社の各種広告をサービス画面に掲載したり利用者の同意を得て、E-mail、SMS、App Push等の方法で利用者に提供できます。',
      ]
    },
    { title: '第5章 個人情報保護', content: '' },
    {

      title: '第18条(個人情報の保護) ',
      content: [
        '会社は、関係法令の定めにより、利用者の個人情報を保護するために努力します。 利用者の個人情報は、サービスの円滑な提供のため、利用者が同意した目的と範囲内でのみ利用されます。 会社は、法令に則ったり利用者が別途同意しない限り、利用者の個人情報を第三者に提供しないし、それに関する詳細については、個人情報処理方針にて定めます。\n',
      ]
    },
    { title: '第6章 損害賠償及び免責条項', content: '' },
    {

      title: '第19条(会社の免責事項及び損害賠償)',
      content: [
        '① 会社はサービスに関連してこの約款に明示していない如何なる事項について保証しません。 また、会社は、会社が発行したり支払いを保証していない暗号通貨の価値を保証しません。\n',
        '② 会社は天災事変、DDos攻撃、IDC障害、サービス接続の輻輳によるサーバーダウン、基幹通信事業者の回線障害等その他不可抗力的理由によりサービスが提供できない場合は、サービス提供に関する責任が免除されます。 但し、会社の故意または重過失による場合、この限りではありません。\n',
        '③ 会社はブロックチェーンの問題、暗号通貨発行管理システム自体の瑕疵、または技術的な問題、通信サービス業者の不良、定期的なサーバー点検等によりやむを得ず障害が発生した場合は責任を負いません。 但し、会社の故意または重過失による場合、この限りではありません。\n',
        '④ 会社は利用者の責に帰すべき理由によるサービス利用の障害やその結果については責任を負いません。 但し、利用者に正当な事由がある場合は、この限りではありません。\n',
        '⑤ 会社は利用者間または利用者と第三者間においてサービスを媒介にして取引等をした場合、責任が免除されます。 但し、会社の故意または重過失による場合、この限りではありません。\n',
        '⑥ 会社は無料で提供されるサービスの利用に関して、関連法に特別な規定がない限り、責任を負いません。\n',
        '⑦ 会社は安定したサービスを提供するために、定期的・不定期的に、または緊急サーバ点検を実施することができます。\n',
        '⑧ 誰であってもサービスエラー、電算障害またはその他の理由により第三者が所有している暗号通貨を権限なく取得したり転送された場合、会社は当事者への事前通知後、当該暗号通貨を回収したり原状回復させる等の必要な措置を取ることができます。\n',
      ]
    },
    {

      title: '第20条(管轄裁判所及び準拠法)',
      content: [
        '① 本サービスの利用約款は、大韓民国の法律に基づいて規律・解釈され、会社の料金体系等サービス利用に関連して会社と利用者間で発生した紛争で訴訟が提起される場合、大韓民国の法律が適用されます。\n',
        '② サービス利用に関連して会社と利用者間で発生した訴訟の場合、法令で定めた手続きに則った裁判所を管轄裁判所とします。\n',
      ]
    },
    { title: '附則', content: '本約款は2020年1月1日より適用されます。' },
  ],

  ///////////////////////////////////////////////////////////////////////////////

  login: 'ログイン',
  password: 'パスワード',

  loginFailedTitle: 'ログイン失敗',
  loginFailedWrongId: '正しくないIDです。',
  loginFailedWrongPassword: '正しくないパスワードです。',
  loginFailedExpiredId: '使用中止されたIDです。',
  loginFailedEmailConfirm: 'メール承認されていません。 eメール承認後ご利用ください。',
  loginFailedMessage: 'ログインに失敗しました。 しばらくしてからもう一度お試しください。',
  loginFailedInvalidLoginInfo: 'ログイン情報が正しくありません。',
  
  myinfoLogout: 'ログアウト',
  myinfoLogoutConfirm: 'ログアウトしますか。',
  passwordModify: 'パスワードの変更',
  changePasswordFailed: 'パスワード変更に失敗しました。',
  changePasswordSuccess: 'パスワード変更が完了しました。ログインを進めてください。',


  /*********내 정보************/
  myPage:'私の情報',
  phone:'電話番号',
  setupOtp: 'OTP設定',
  changeOtp: 'OTP変更',
  certiKYC:'KYC認証',
  change: '変更する',
  close:'閉じる',
  saveData:'保存する',
  nowPw:'現在のパスワード',
  newPw: '新しいパスワード',
  changePasswordFailedSamePassword: '既存のパスワードと新しいパスワードが同じです。',
  changePasswordFailedInvalidPassword: 'パスワードが間違っています。',
  myinfoTwoSecurityInfo: '二重認証キーは財布から引き出す時、セキュリティを強化させる6桁のキーです。 App StoreからGoogle OTPもしくはAuthyをダウンロードしてください。',
  myInfoOtpMail : 'メールで送信された認証コード6桁を入力してください。',
  otpCopied: 'OTPキーがコピーされました。',
  submit:'提出する',
  inputPhone: '電話番号入力',
  sendCertiCode2: '認証番号 発送',
  phoneFail: '携帯電話番号を正確に入力してください。',
  checkCertiCode: '認証番号確認',
  certiCode: '認証番号',
  certiCodeExpired: '認証期限を超えています。 認証番号をもう一度転送してください。',
  inputCertiCode: '認証番号入力',
  inputCurrentSecPassword: '現在のセキュリティパスワード入力(4桁)',
  inputNewSecPassword: '新しいセキュリティパスワード入力(4桁)',
  inputCurrentPassword: '現在のパスワード入力',
  inputNewPassword: '新しいパスワード入力',
  kycOTPerror:'OTPの登録を先にしてください。',
  certiCompleted:'認証完了',
  auditing:'審査中',
  enterNicknameValidation: '名前は英語で入力してください。',
  setSucceed: '変更が完了しました。 もう一度ログインしてください。',
  setFailed: '変更に失敗しました。 管理者にお問い合わせください。',
  kycInfo:'白い紙に「タイガーウォレット」と今日の日付を書いて身分証と一緒に持って顔が出るように写真を撮影してください。',
  alreadyOtp: 'OTPはすでに発行されています。',
  myinfoOtpComplete: 'OTP設定が完了しました。',
  myinfoOtpError: '認証コードまたはOTPコードが無効です。',
  changeOtpComplete: '携帯電話認証を完了しました。 もう一度OTPを設定してください。',
  captureError:'撮影に失敗しました。 もう一度撮影してください。',
  fileUploadComplete: 'ファイルが登録されました。',
  fileUploadError: 'ファイルの登録に失敗しました。 管理者にお問い合わせください。',




};

export default string;
