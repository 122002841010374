import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../css/signUp.scss";
import string from "../../language/StringKo";

class WalletList extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
      <>
        <div className="wallet-list-overlay" onClick={this.props.close}/>
        <div className="wallet-list-wrapper">
          <div style={{borderBottom: "1px solid", padding: 10}}>{string.selectCoin}</div>
          { this.props.coinList.map(item => {
            console.log("vvvvvvvvvvvvvv " + item.coinType)
            console.log("11111111111 " + this.props.coinType)
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderBottom: "1px solid",
                  marginBottom: 10,
                  padding: 10
                }}
                key={item.coinType}
                onClick={()=>this.props.setCoinType(item.coinType)}
              >
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                  <img src={item.image} style={{height: 40, width: 40, marginRight: 10}} /> 
                  <div>
                    {item.name}
                    <span>{item.symbol}</span>
                  </div>
                </div>
                <img
                  src={require('../../img/common/check.png')}
                  className = {this.props.coinType == item.coinType ? "wallet-list-img-checked" : "wallet-list-img-unchecked"}
                />
              </div>
            )
          })

          }
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
      language: state.stringReducer.language,
      string: state.stringReducer.string,
      isLogin: state.loginReducer.isLogin,
      userinfo: state.loginReducer.userinfo,
      coinList: state.coinListReducer,
  };
};

export default withRouter(connect(mapStateToProps)(WalletList));