import { Form, Input, Checkbox, Button, Modal } from 'antd';
import React from 'react';
import "../css/signUp.scss";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { stringReducer, setLogin, setLogout } from "../actions";
import { Link, Route, withRouter } from "react-router-dom";
import { formatBalance } from "../util/coinUtil";
import string from '../language/StringKo';
import Header from '../components/Header';
import Top from './Top';
const FormItem = Form.Item;

class Exchange extends React.Component {
    constructor(props) {
        super(props);
        // string = Language.getString();

        this.state = {
            selectedCoinType: this.props.selectedCoinType,
            tableData: [],
            isShowCoinSelect: false,

            currency: global.currency,
            exchangeRate: global.exchangeRate,

            active: 0,
        }
        this.currenctPageNum = 1;
        this.pageSize = 10;
        this.latestPageNum = 1;
        this.requesting = false;
    }
    componentDidMount() {
        // this.navigationEventListener = Navigation.events().bindComponent(this);
    }
    componentWillUnmount() {
        if (this.navigationEventListener) {
            this.navigationEventListener.remove();
        }
    }
    setActive = (idx) => {
        this.setState({
            tableData: [],
            isShowCoinSelect: false,
            active: idx
        }, () => {
            this.initialLoadActive();
        });

    }
    addCoin(coinType) {
        httpPost(httpUrl.addCoin, [], { coinType: coinType })
            .then(result => {
                console.log('addCoin result: ' + JSON.stringify(result));
                result = result.data;

                console.log('## addCoin success');
            })
            .catch(e => {
                console.log('## addCoin error: ' + e);
            });
    }
    calTotalValue() {
        let totalValue = 0;
        let allLoaded = true;
        this.props.coinList.forEach(coin => {
            allLoaded &= coin.valueLoaded;
            totalValue += coin.value;
        });
        // if (allLoaded) return totalValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // else return '-';
        // return (totalValue * this.state.exchangeRate[this.state.currency]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return (totalValue * this.state.exchangeRate[this.state.currency]).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
    // toggleCoinSelect = () => {
    //     console.log('toggleCoinSelect..');
    //     Navigation.showModal({
    //         component: {
    //             name: 'navigation.CoinSelectDialog',
    //             passProps: {
    //                 coinList: this.props.coinList,
    //                 onChangeSelectedCoinType: this.onChangeSelectedCoinType,
    //                 selectedCoinType: this.state.selectedCoinType,
    //             },
    //             options: {
    //                 topBar: { drawBehind: true, visible: false },
    //                 screenBackgroundColor: 'transparent',
    //                 modalPresentationStyle: 'overCurrentCondiv',
    //             },
    //         },
    //     });
    // }
    initialLoadActive = () => {
        this.setState({
            tableData: [],
        })
        this.currenctPageNum = 1;
        this.pageSize = 10;
        this.latestPageNum = 1;

        this.props.requestBalance(this.state.selectedCoinType);

        if (this.state.active == 0) this.getTradeList();
        else if (this.state.active == 1) this.getExchangeList();
        else if (this.state.active == 2) this.getPayList();
    }
    scrollLoadActive = () => {
        if (this.state.active == 0) this.getTradeList();
        else if (this.state.active == 1) this.getExchangeList();
        else if (this.state.active == 2) this.getPayList();
    }
    onChangeSelectedCoinType = (idx) => {
        this.setState({ selectedCoinType: idx }, () => {
            this.initialLoadActive();
        });
    };
    render() {
        console.log(this.props.userinfo)
        const { language, string } = this.props;
        return (
            <div className="wrapper">
                <Top />
                <div className="container">
                    <Header />
                    <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                        <div className="main-box">
                            <div className="main-title">
                                <div className="main-text">공지사항</div>
                                <div className="more-btn">
                                </div>
                            </div>
                            <div className="currencyNotice">

                            </div>
                        </div>
                        <div className="" >

                            {/* 사용코인 */}

                            <div style={{
                                height: 100,
                                backgroundColor: '#fff',
                                borderRadius: 10,
                                padding: 20
                            }}>
                                <div>
                                    {/* {string.exchangeUseCoin} */}
                                </div>

                                <div style={{ marginVertical: 5 }}>

                                    <div style={{ flexDirection: 'row', alignItems: 'center' }}>

                                        <div style={{ flex: 1 }}>
                                            <Button
                                                style={{ flexDirection: 'row', alignItems: 'center' }}
                                                onPress={this.toggleCoinSelect}>

                                                <div
                                                    style={{
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                    {/* <ScalableImage
                                                        source={require('../img/exchange/zz.png')}
                                                        width={30} /> */}

                                                </div>

                                                {/* <div style={{ marginLeft: 10, width: 60, divAlign: 'center', fontSize: 14.7 }}>{string.coinNameZZ} {string.coin}</div> */}

                                                <div style={{ justifyContent: 'center', marginLeft: 5 }}>
                                                    {/* <Image source={require('../img/common/dropdown_arrow.png')} /> */}
                                                </div>

                                            </Button>
                                        </div>

                                        <div style={{ width: 160, height: 40, marginLeft: 15, flex: 1.5 }}>
                                            {/* <TextInput
                                                style={{ borderWidth: 1, borderColor: color.lightgray, borderRadius: 10, paddingLeft: 10 }}
                                                placeholder={string.miningInputAmount}
                                                keyboardType="numeric"
                                            /> */}
                                        </div>
                                    </div>

                                </div>

                            </div>


                            {/* 받는코인 */}

                            <div style={{
                                height: 100,
                                backgroundColor: '#fff',
                                borderRadius: 10,
                                elevation: 1,
                                shadowColor: 'black',
                                shadowOffset: { width: 1, height: 1 },
                                shadowOpacity: 0.2,
                                shadowRadius: 0.5,
                                padding: 20,
                                marginTop: 10
                            }}>
                                <div>
                                    {/* <div style={{ paddingLeft: 5, color: '#686868' }}>{string.exchangeReceiveCoin}</div> */}
                                </div>

                                <div style={{ marginVertical: 5 }}>

                                    <div style={{ flexDirection: 'row', alignItems: 'center' }}>

                                        <div style={{ flex: 1 }}>
                                            <Button
                                                style={{ flexDirection: 'row', alignItems: 'center' }}
                                                onPress={this.toggleCoinSelect}>

                                                <div
                                                    style={{
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                    {/* <ScalableImage
                                                        source={require('../img/exchange/eth-logo.png')}
                                                        width={30} /> */}
                                                </div>

                                                {/* <div style={{ marginLeft: 10, width: 60, divAlign: 'center', fontSize: 14.7 }}>{string.coinNameETH} {string.coin}</div> */}

                                                <div style={{ justifyContent: 'center', marginLeft: 5 }}>
                                                    {/* <Image source={require('../img/common/dropdown_arrow.png')} /> */}
                                                </div>

                                            </Button>
                                        </div>

                                        <div style={{ width: 160, height: 40, marginLeft: 15, flex: 1.5 }}>
                                            {/* <TextInput
                                            style={{ borderWidth: 1, borderColor: color.lightgray, borderRadius: 10, paddingLeft: 10, }}
                                            placeholder={string.miningInputAmount}
                                            keyboardType="numeric"
                                        /> */}
                                        </div>
                                    </div>
                                </div>

                            </div>



                            <div style={{ flex: 1.5, width: '100%', paddingHorizontal: 20, marginTop: 10 }}>

                                <div style={{ flexDirection: 'row', marginVertical: 10 }}>
                                    {/* <div style={{ width: 60 }}>{string.exchangeRate}</div>
                                <div style={{}}>{string.RateZZtoETH}</div> */}
                                </div>

                                <div style={{ flexDirection: 'row' }}>
                                    {/* <div style={{ width: 60 }}>{string.fee}</div>
                                <div style={{}}>{string.feeOneday}</div> */}
                                </div>

                            </div>
                        </div>



                        <div>


                            <Button>

                                {/* {string.exchangeCoinConfirmDlgTtl} */}

                            </Button>



                        </div>

                    </div>

                </div>
            </div >
        )
    }
}
export default Exchange;