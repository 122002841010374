import { Form, Input, Checkbox, Button, Modal, Table, Divider } from 'antd';
import React from 'react';
import "../../css/signUp.scss";
import { httpGet, httpUrl, httpPost } from "../../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { stringReducer, setLogin, setLogout } from "../../actions";
import { Link, Route, withRouter } from "react-router-dom";
import { formatBalance } from "../../util/coinUtil";
import string from '../../language/StringKo';
import moment from "moment";
import QRCode from "react-qr-code";
import { comma } from '../../util/numberUtil';
import Header from '../Header';
import { toHaveStyle } from '@testing-library/jest-dom';
import Top from '../../pages/Top';
import { LeftSquareOutlined, } from '@ant-design/icons';

const FormItem = Form.Item;

class WalletReceive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10,
            },
            list: [],
            priceInputShow: false,
            priceInputOk: false,
            priceOk: false,
            price: "",

        }
    }

    handleTableChange = (pagination) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        }, () => this.getList());
    };


    componentDidMount() {
        this.getList()
    }


    getList = () => {


    }

    copy = () => {
        const text = document.createElement("textarea")
        document.body.appendChild(text)
        text.value = this.props.location.state.data.address
        text.select();
        document.execCommand('copy');
        document.body.removeChild(text);
        Modal.info({
            content: this.props.string.copyComplete
        })
    }

    back = () => {
        this.props.history.goBack();
    }

    inputOk = (price) => {
        this.setState({
            price,
        }, console.log("ASDFASDFASD"));
    }

    render() {
        const { language, string } = this.props;



        const { close } = this.props;

        const data = this.props.coinList.find(x => x.coinType === this.state.coinType)
        return (
            <div className="wrapper">
                <Top />
                <div className="container">
                    <Header />
                    <div className="container-box" style={{ textAlign: 'center', paddingBottom: '30px' }}>
                        <div className="main-box">
                            <div className="main-title">
                                <div className="back-btn" onClick={this.back}>
                                    <LeftSquareOutlined style={{ fontSize: 30 }} />
                                </div>
                                {/* <div className="main-text">{string.address}</div> */}
                                {this.state.priceInputShow && !this.state.priceOk ?
                                    <>
                                        <div className="more-btn2">
                                            <Button
                                                style={{ borderRadius: 10, width: 100, height: 40, color: 'gray', fontWeight: 'bold' }}
                                                onClick={() => this.setState({
                                                    priceOk: true,
                                                })}
                                            >{string.ok}
                                            </Button>
                                        </div>
                                        <div className="more-btn2">
                                            <Button

                                                onClick={() => this.setState({
                                                    priceInputShow: false,
                                                })}
                                            >{string.cancel}
                                            </Button>
                                        </div>
                                    </>
                                    :
                                    <>
                                        {/* <div className="more-btn2">
                                            <Button
                                                style={{ borderRadius: 10, width: 100, height: 40, color: 'gray', fontWeight: 'bold' }}
                                                onClick={() => this.setState({
                                                    priceInputShow: true,
                                                    priceInputOk: true,
                                                    priceOk: false,
                                                })}
                                            >{string.priceInsert}
                                            </Button>
                                        </div> */}
                                        <div className="more-btn2">
                                            <Button
                                                onClick={this.copy}
                                            >{string.copyAddress}
                                            </Button>
                                        </div>
                                    </>


                                }
                            </div>
                        </div>
                        <div className="charge-inner">
                            <QRCode
                                // value={this.state.sele == '' ? this.props.address : this.props.address + "?value=" + this.state.price + "&coinType=" + this.props.coin.symbol}
                                size={200}
                                color='#000'
                                value={this.props.location.state.data.address}
                            />
                            <div className="address-block">
                                <div className="content-box address-box">{this.props.location.state.data.address}</div>

                            </div>
                            {this.state.priceInputShow &&
                                <div style={{ display: "inline-block" }}>{string.qrContainPrice}:
                                    {this.state.priceOk ?
                                        <div style={{ display: "inline-block", marginLeft: 20 }}>{this.state.price}</div>
                                        :
                                        <Input style={{ width: 200, marginLeft: 20 }}
                                            value={this.state.price}
                                            onChange={(e) => this.setState({
                                                price: e.target.value
                                            })} />
                                    }
                                    <span style={{ marginLeft: 10 }}>{this.props.location.state.data.symbol}</span>
                                </div>
                            }

                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string,
        isLogin: state.loginReducer.isLogin,
        userinfo: state.loginReducer.userinfo,
        coinList: state.coinListReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLanguage: lan => {
            dispatch(stringReducer(lan));
        },
        setLogin: userinfo => {
            dispatch(setLogin(userinfo));
        },
        setLogout: lan => {
            dispatch(setLogout());
        },
    };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WalletReceive));