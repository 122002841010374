import { Form, Input, Checkbox, Button, Modal, Divider, Image } from 'antd';
import React from 'react';
import "../css/signUp.scss";
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto';
import { connect } from "react-redux";
import { stringReducer, setLogin, setLogout } from "../actions";
import { Link, Route, withRouter } from "react-router-dom";
import { formatBalance } from "../util/coinUtil";
import string from '../language/StringKo';
import Header from '../components/Header';
import mining from '../img/mining/mining-bg.png';
import Coin from '../lib/coin/coin';
import NumberFormat from '../lib/util/numberformat';

const FormItem = Form.Item;

class Mining extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rewardToday: 0,

            currentReward: 0,
            maxReward: 0,

            investAmount1: 0,
            investAmount2: 0,

            refundAmount1: 0,
            refundAmount2: 0
        }
    }

    componentDidMount() {
        // this.navigationEventListener = Navigation.events().bindComponent(this);
    }
    componentWillUnmount() {
        // if (this.navigationEventListener) {
        //     this.navigationEventListener.remove();
        // }
    }

    getTodayReward = () => {
        httpGet(httpUrl.miningTodayReward, []).then((result) => {
          result = result.data;
          this.setState({rewardToday: result})
        });
      }

      getTotalReward = () => {
        httpGet(httpUrl.miningTotalReward, []).then((result) => {
          result = result.data;
          this.setState({
            currentReward: result.currentReward,
            maxReward: result.maxReward
          })
        });
      }
      getTotalInvest = () => {
        httpGet(httpUrl.miningTotalInvest, []).then((result) => {
          result = result.data;
          this.setState({
            investAmount1: result.amount1,
            investAmount2: result.amount2
          })
        });
      }
      getRefundAmount = () => {
        httpGet(httpUrl.miningRefundAmount, []).then((result) => {
          result = result.data;
          this.setState({
            refundAmount1: result.amount1,
            refundAmount2: result.amount2
          })
        });
      }
    // modal = () => {
    //   Navigation.showModal({
    //     component: {
    //       name: 'navigation.AccountDialog'
    //     },
    //   })
    // }

    // MiningCancelDialog = () => {
    //     Navigation.showModal({
    //         component: {
    //             name: 'navigation.MiningCancelDialog',
    //             passProps: {
    //                 title: string.MiningCancelTitle,
    //                 message1: string.MiningCancelsub1,
    //                 message2: string.MiningCancelsub2,
    //                 message3: string.MiningCancelsub3,
    //                 ok: this.props.logout,
    //             },
    //             options: {
    //                 topBar: { drawBehind: true, visible: false },
    //                 screenBackgroundColor: 'transparent',
    //                 modalPresentationStyle: 'overCurrentContext',
    //             },
    //         },
    //     });
    // };

    // Miningdetail = () => {
    //     Navigation.showModal({
    //         component: {
    //             name: 'navigation.MiningDetail'
    //         }
    //     })
    // }

    // MiningInvestment = () => {
    //     Navigation.showModal({
    //         component: {
    //             name: 'navigation.MiningInvestment'
    //         }
    //     })
    // }

    render() {
        console.log(this.props.userinfo)
        const { language, string } = this.props;
        return (
            <div className="wrapper">
                <div className="BT-HD-right-container">
                    <div className={`BT-HD-right-element ${language == 'ja' ? 'color-blue' : ''}`} onClick={this.logout}>
                        LOGOUT
                    </div>
                </div>
                <div className="header">
                    {/* {string.welcome} */}
                </div>

                <div className="container">
                    <Header />

                    <div className="container-box" style={{ paddingBottom: '30px' }}>

                      <div style={{textAlign: 'right'}}>
                        <Link to={"/miningDetail"}>
                        <Button style={{borderRadius: 10, width: 100, height: 40, marginBottom:20 ,fontWeight:'bold',color:'gray'}}>
                        {string.miningDetail}</Button>
                        </Link>
                        </div>
                        {/* <Button onPress={() => this.modal()}>
                   <Text>{string.miningDetail}</Text>
                     </Button> */}

                        {/* 컨텐츠 */}

                        {/* 상단 이미지 부분 */}
                     <div className="mining-bg">              
                      
                         

                            <div style={{color:'white', fontSize:23, fontWeight:'bold', paddingTop:50 }}>
                                {string.miningToday}                              
                            </div>
                            <div className="wallet-titbox">
                            <div className="mining-logo"></div>
                            {(this.state.rewardToday  / Coin.getCoinUnit(203).base).toFixed(6)} {string.coinNameZZ}
                            </div>                            
                        </div>
                        {/* 중간 */}

                        <div style={{ flex: 1.2, paddingTop: 15, paddingLeft: 20, paddingRight: 20, paddingBottom: 20 }}>
                            <div style={{
                                backgroundColor: '#fff',
                                borderRadius: 10,
                                padding: 20,
                                elevation: 1,
                                shadowColor: 'black',
                                shadowOffset: { width: 1, height: 1 },
                                shadowOpacity: 0.2,
                                shadowRadius: 0.5,
                            }} >
                                <div>
                                    <div style={{ fontWeight:'500', display:'flex', justifyContent: 'space-between' }}>
                                  <div>
                                  {string.miningAccued}
                                  </div>
                                  <div>
                                  {NumberFormat.commaNumber(this.state.currentReward)} / {NumberFormat.commaNumber(this.state.maxReward)}
                                  </div>
                                  </div>  
                                  
                                   
                                 <div style={{color:'#5663ff', textAlign: 'center'}}> 
                                    <Divider/>
                                    {string.miningGiveInfo}                               
                                </div>
                              
                            </div>
                        </div>

                        {/* 하단 */}

                        <div style={{ flex: 2, paddingTop: 10, paddingHorizontal: 20, paddingBottom: 10, marginTop: 40 }}>
                            <div style={{
                                backgroundColor: '#fff',
                                borderRadius: 10,
                                padding: 20,
                                elevation: 1,
                                shadowColor: 'black',
                                shadowOffset: { width: 1, height: 1 },
                                shadowOpacity: 0.2,
                                shadowRadius: 0.5,
                            }} >
                                {/* <Text style={{ fontSize: 16, fontWeight: '600', color: '#868686' }}>{string.miningInvestment}</Text> */}

                                <div style={{ flexDirection: 'row', paddingTop: 10, paddingBottom: 10, textAlign: 'left', color:'gray' ,fontSize:15}}>
                                    {string.miningInvestment}                                
                                </div>
                                <div className="mining-myinvest" >
                                    <div className="mining-myinvest-content1">
                                    ZZ코인 <br />
                                    이더리움
                                    </div>
                                    <div className="mining-myinvest-content2">
                                    <Image width={50} height={25} src={require('../img/mining/mining-arrow2.png')} /><br />
                                    <Image width={50} height={25} src={require('../img/mining/mining-arrow2.png')} />
                                    </div>                                  
                                    <div className="mining-myinvest-content3">
                                    {(this.state.investAmount2 / Coin.getCoinUnit(203).base).toFixed(6)} {string.coinNameZZ}<br />
                                    {(this.state.investAmount1 / Coin.getCoinUnit(2).base).toFixed(6)} {string.coinNameETH}
                                     </div>
                                </div>

                            </div>

                            <div style={{ height: 100, display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center',marginTop:50, textAlign:'center'}}>
                               <Button 
                                    style={{ color:'white', paddingLeft: 50, paddingRight: 50, height: 80 , width:300, fontSize:22,
                                    backgroundImage:"linear-gradient(to right, #43b4f8, #6a46ff)", borderRadius: 40, alignItems: 'center', justifyContent: 'center' }}>                                     
                                     {string.miningDoInvestment}
                               </Button>                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>

         </div >

        )
    }
}

const mapStateToProps = state => {
    return {
        language: state.stringReducer.language,
        string: state.stringReducer.string,
        isLogin: state.loginReducer.isLogin,
        userinfo: state.loginReducer.userinfo,
        coinList: state.coinListReducer,
    };
};

export default withRouter(connect(mapStateToProps)(Mining));